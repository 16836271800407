
import { Row, Tag, Divider } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";


function DataColumnsCar({ isMobile }) {


    const [expandedRowsPay, setExpandedRowsPay] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    const handleToggleExpand = (key) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(key)
                ? prevExpandedRows.filter((rowKey) => rowKey !== key) // ถ้าแถวนี้ถูกขยายอยู่แล้ว, ให้ยุบ
                : [...prevExpandedRows, key] // ถ้ายังไม่ขยาย, ให้ขยาย
        );
    };
    const handleToggleExpandPay = (key) => {
        setExpandedRowsPay((prevExpandedRows) =>
            prevExpandedRows.includes(key)
                ? prevExpandedRows.filter((rowKey) => rowKey !== key) // ถ้าแถวนี้ถูกขยายอยู่แล้ว, ให้ยุบ
                : [...prevExpandedRows, key] // ถ้ายังไม่ขยาย, ให้ขยาย
        );
    };



    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return 0;
        }
    };

    

    return  [
        {
            title: <div style={{ textAlign: "center" }}>ลำดับ</div>,
            dataIndex: "key",
            key: "key",
            align: "center",
            onCell: () => ({
                "key": "ลำดับ",
            }),
            // render: (text, object, key) => key + 1,
            render: (text, record, index) => {
                // เช็คว่าเป็นมือถือหรือไม่
                return isMobile ? <p style={{ padding: '20px' }} /> : index + 1; // แสดง '-' บนมือถือ, แสดงลำดับตาม index บน Desktop
            },
            sorter: {
                compare: (a, b) => a.key - b.key,
                multiple: 5,
            },
        },
        {
            title: "รายละเอียด",
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: "center",
            onCell: () => ({
                "key": "ลำดับ",
            }),
            render: (text, record1) => {
                const dataSource = [record1]
                const filteredData = dataSource.filter(record => record.products !== 'land');
                if (record1?.products === 'land') {
                    return null;
                }

                const dataNew = () => {
                    return (
                        <>
                            <div> เลขสัญญา <b>{record1?.CONTNO ? record1?.CONTNO : null}</b> </div>
                            <div>{record1.SNAM} {record1.NAME1} {record1.NAME2}</div>
                            {
                                record1.BAAB || record1.TYPE || record1.MODEL || record1.MANUYR || record1.REGNO ?
                                    <div>
                                        {record1.TYPE ? record1.TYPE + ' ' : null}
                                        {/* {record1.MODEL ? record1.MODEL + ' ' : null} */}
                                        {record1.BAAB ? record1.BAAB + ' ' : null}
                                        {record1.REGNO ? ' เลขทะเบียน' + ' ' + record1.REGNO : null}
                                    </div>
                                    : null
                            }
                        </>
                    )
                }

                return (
                    <div>
                        {dataNew()}
                    </div>
                );
            }
        },
        {
            title: "ที่อยู่",
            dataIndex: "addresses",
            key: "addresses",
            align: "center",
            onCell: () => ({
                "key": "ลำดับ",
            }),
            render: (text, record) => {
                return (
                    <div data-label="ที่อยู่ : " style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        {
                            record?.addresses?.length > 1 ?
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "8px",
                                            height: "100%",
                                            color: 'white',
                                            background: '#6b77ab',
                                            border: "2px solid #302e94f7", // เส้นกรอบรอบข้อมูล
                                            borderRadius: "8px", // เพิ่มมุมโค้งให้เส้นกรอบ
                                            margin: "8px 0", // ระยะห่างระหว่างแต่ละชุดข้อมูล
                                            boxShadow: "0 2px 4px rgba(29, 13, 102, 0.1)", // เพิ่มเงาให้ดูสวยงาม
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleToggleExpandPay(record.key)}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    color: 'whtie',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {record?.addresses[0]?.ADDRESS ? ' ' + record?.addresses[0]?.ADDRESS + ' ' : null}
                                                {record?.addresses[0]?.AUMP ? ' ' + record?.addresses[0]?.AUMP + ' ' : null}
                                                {record?.addresses[0]?.TUMB ? ' ' + record?.addresses[0]?.TUMB + ' ' : null}
                                                {record?.addresses[0]?.PROVDES ? ' ' + record?.addresses[0]?.PROVDES + ' ' : null}
                                                {record?.addresses[0]?.ZIP ? ' ' + record?.addresses[0]?.ZIP + ' ' : null}
                                            </span>
                                        </div>

                                        <span style={{ marginLeft: '10px' }}>
                                            {expandedRowsPay.includes(record.key) ? (
                                                <FaSortUp size={20} /> // แสดงลูกศรลงเมื่อขยาย
                                            ) : (
                                                <FaSortDown size={20} /> // แสดงลูกศรขวาเมื่อยุบ
                                            )}
                                        </span>

                                        {record?.addresses?.map((item, i) => {
                                            return (
                                                <React.Fragment key={`data-${record.key}-${i}`}> {/* เพิ่ม key */}
                                                    {
                                                        expandedRowsPay.includes(record.key) && (
                                                            <>
                                                                <Divider style={{ margin: 3 }} />
                                                                <div
                                                                    style={{
                                                                        marginTop: '10px',
                                                                        paddingLeft: '5px',
                                                                        backgroundColor: 'green',
                                                                        color: 'white',
                                                                        borderRadius: "8px",
                                                                        padding: '8px',
                                                                    }}
                                                                >
                                                                    <span><b><u>ลำดับที่ : {i + 1}</u></b></span>
                                                                    <div>
                                                                        {item.ADDRESS ? ' ' + item.ADDRESS + ' ' : null}
                                                                        {item.AUMP ? ' ' + item.AUMP + ' ' : null}
                                                                        {item.TUMB ? ' ' + item.TUMB + ' ' : null}
                                                                        {item.PROVDES ? ' ' + item.PROVDES + ' ' : null}
                                                                        {item.ZIP ? ' ' + item.ZIP + ' ' : null}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <>
                                    {record?.addresses?.map((item, i) => {
                                        return (
                                            <React.Fragment key={`data-${record.key}-${i}`}> {/* เพิ่ม key */}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: "8px",
                                                        height: "100%",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "8px",
                                                        margin: "8px 0",
                                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                                                    }}
                                                >
                                                    <div>
                                                        {item.ADDRESS ? ' ' + item.ADDRESS + ' ' : null}
                                                        {item.AUMP ? ' ' + item.AUMP + ' ' : null}
                                                        {item.TUMB ? ' ' + item.TUMB + ' ' : null}
                                                        {item.PROVDES ? ' ' + item.PROVDES + ' ' : null}
                                                        {item.ZIP ? ' ' + item.ZIP + ' ' : null}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </>
                        }
                    </div >
                );
            }
        },
        {
            title: "การชำระ",
            dataIndex: "Payment",
            key: "Payment",
            align: "center",
            render: (text, record) => (
                <>
                    <div data-label="การชำระ : " style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <div>
                            <span>
                                จ่ายขั้นต่ำ : { }
                            </span>
                            {record.PAYLIMITAMT ? currencyFormatOne(record.PAYLIMITAMT) + ' ' + 'บาท' : '-'}
                        </div>
                        <div>
                            <span>
                                วันที่จ่าย : { }
                            </span>
                            {record.PAYDT ? dayjs(record.PAYDT).format("DD-MM-YYYY") : '-'}
                        </div>
                        <div>
                            <span>
                                ยอดที่จ่าย : { }
                            </span>
                            {record.NETPAY ? currencyFormatOne(record.NETPAY) + ' ' + 'บาท' : '-'}
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: "เกรด",
            dataIndex: "GRDCOD",
            key: "GRDCOD",
            align: "center",
            render: (text, record) => {

                return (
                    <div data-label="เกรด : " style={{ textAlign: 'center' }} >
                        {record?.GRDCOD ?
                            <span
                                style={{
                                    // color: 'white',
                                    // background: '#6b77ab',
                                    padding: '12px',
                                    fontSize: '18px',
                                    borderRadius: '5px',
                                }}
                            >
                                <b>
                                    {record?.GRDCOD}
                                </b >
                            </span>
                            : "-"}
                    </div>
                )
            }
        },
        {
            title: "สถานะการจ่าย",
            dataIndex: "ApprovalStatus",
            key: "ApprovalStatus",
            align: "center",
            render: (text, record) => {
                const netPay = Number(record.NETPAY);
                const payLimitAmt = Number(record.PAYLIMITAMT);
                if (!isNaN(netPay) && !isNaN(payLimitAmt)) {
                    const check = netPay >= payLimitAmt
                    return (
                        <div data-label="สถานะการจ่าย : " style={{ textAlign: 'center' }} >
                            {check ? <Tag style={{ background: 'green', color: 'white', fontSize: '16px', padding: 5 }} > จ่ายแล้ว</Tag > : "-"}
                        </div>
                    )
                }
            }
        },
        {
            title: "สถานะสัญญา",
            dataIndex: "ApprovalStatus",
            key: "ApprovalStatus",
            align: "center",
            render: (text, record) => {
                // console.log("record", record)
                const netPay = Number(record.NETPAY);
                const payLimitAmt = Number(record.PAYLIMITAMT);
                if (!isNaN(netPay) && !isNaN(payLimitAmt)) {
                    const check = netPay >= payLimitAmt
                    return (
                        <>
                            <div data-label="สถานะสัญญา : " style={{ textAlign: 'center' }}>
                                {
                                    record?.flagTellAun ? <Tag style={{ background: "#a09df0", color: 'white', fontSize: '16px', padding: 5 }} >เร่งรัดนอก</Tag>
                                        : record?.flagClose ? <Tag style={{ background: "#e6283beb", color: 'white', fontSize: '16px', padding: 5 }}>เคสปิด</Tag>
                                            : record?.flagOffer ? <Tag style={{ background: "blue", color: 'white', fontSize: '16px', padding: 5 }}>ส่งการตลาด</Tag>
                                                : record?.flagTellAun && record?.flagOffer ? <Tag style={{ background: "blue", color: 'white', fontSize: '16px', padding: 5 }}>ส่งการตลาด</Tag>
                                                    : "-"
                                }
                            </div>
                        </>
                    )
                }
            }
        },
    ]

}

export default DataColumnsCar;

