import { Col, Row, Spin, Card, Table, DatePicker, message, Form, Select, Button } from 'antd'
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import dayjs from "dayjs";
import { getcts_detail } from '../mid/all-api';
import { FaDatabase } from 'react-icons/fa';
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { font } from "../../fonts/THSarabunNew-normal";
import ExcelJS from 'exceljs';

function MainPay() {
    // const getcts_detail = `http://localhost:8080/api-db2/getcts_detail`
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [dataArray, setDataArray] = useState()
    const dayCurrent = dayjs(); // วันที่ปัจจุบัน
    const dayBeforeCurrent = dayjs().subtract(1, 'day'); // วันที่ก่อนหน้าวันปัจจุบัน 1 วัน
    const [dateSearch, setDateSearch] = useState("")
    const user = localStorage.getItem("username")
    const nicknameSalcod = localStorage.getItem("nicknameSalcod")
    const [selectedValueType, setSelectedValueType] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    const [scrollY, setScrollY] = useState(window.innerHeight);
    const [checkLoadData, setCheckLoadData] = useState(0);
    const thaiFont = font;

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 600);
            setScrollY(window.innerHeight);
        }
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);


    // คำนวณความสูงของตารางโดยหักลบส่วนอื่นๆ
    const calculateTableHeight = () => {
        const headerHeight = 90; // ความสูงของ Header
        const footerHeight = isMobile ? 110 : 0; // ความสูงของ Footer (มือถือมี Pagination)
        const marginHeight = 40; // Margin ระหว่างส่วนต่างๆ
        const otherComponentsHeight = 90; // ความสูงของส่วนอื่นๆ (ปุ่ม, Select, Input ฯลฯ)
        return scrollY - (headerHeight + footerHeight + marginHeight + otherComponentsHeight);
    };

    const exportToPDF = async () => {
        console.log("exportToPDF")
        // const { jsPDF } = window.jspdf;
        const doc = new jsPDF({
            orientation: "landscape", // ตั้งค่ากระดาษแนวนอน
            unit: "mm", // หน่วย (เช่น mm, pt)
            format: "a4" // ขนาดกระดาษ (A4, Letter, ฯลฯ)
        });
        const result = await testreportandexcel(); // ดึงข้อมูลที่เตรียมไว้

        // ฝังฟอนต์ไทยใน PDF
        doc.addFileToVFS("THSarabunNew.ttf", thaiFont);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");

        // ตั้งค่าให้ใช้ฟอนต์ไทย
        doc.setFont("THSarabunNew");
        doc.setFontSize(16);

        doc.text(`รายงานลูกหนี้ - ${user}`, doc.internal.pageSize.width / 2, 10, {
            align: "center" // จัดข้อความให้อยู่ตรงกลาง
        });

        // รวมข้อมูลโดยการอัปเดต Status ใน dataArr จาก result
        const mergedData = dataArray.map(item => {
            // หา Status ที่ตรงกับ ARCONTNO ใน result
            const matchedResult = result?.filter((res) =>
                res.CONTNO === item.CONTNO
            );
            // ถ้าเจอ matchedResult ให้อัปเดต Status
            return {
                ...item,
                Status: matchedResult ? matchedResult[0]?.Status : item.Status // ใช้ Status จาก result ถ้ามี
            };
        });
        // console.log("mergedData", mergedData)
        // เขียนข้อความภาษาไทย
        const headers = ["เลขสัญญา", "REF2", "เงินที่จ่าย", "วันที่จ่าย"];
        const data = mergedData.map(item => [
            item?.CONTNO,
            item?.REF2,
            currencyFormatOne(item?.money),
            dayjs(item?.PAYMENTDATE).format("DD-MM-YYYY")
        ]);
        // สร้างตาราง
        doc.autoTable({
            head: [headers],
            body: data,
            startY: 15, // เริ่มวาดตารางจากตำแหน่ง Y = 40
            styles: {
                font: "THSarabunNew", // ใช้ฟอนต์ไทยที่เราฝังไว้
                fontSize: 14 // ขนาดฟอนต์ในตาราง
            },
            headStyles: {
                fillColor: [100, 149, 237], // สีพื้นหลังหัวตาราง (Cornflower Blue)
                textColor: [255, 255, 255]  // สีข้อความในหัวตาราง (สีขาว)
            },
            didDrawPage: (data) => {
                // เพิ่มหมายเลขหน้า
                const pageNumber = doc.internal.getNumberOfPages();
                doc.setFontSize(12);
                doc.text(`หน้าที่ ${pageNumber}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
            }
        });
        doc.save("report-th.pdf");
    }

    const exportToExcel = async () => {
        const user = localStorage.getItem("username") || "ไม่ระบุชื่อผู้ใช้งาน";
        const result = await testreportandexcel(); // ดึงข้อมูลที่เตรียมไว้
        const workbook = new ExcelJS.Workbook();
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
            const worksheet = workbook.addWorksheet(sheetName);
            worksheet.mergeCells("A1:D1"); // รวมเซลล์
            const titleCell = worksheet.getCell("A1");
            titleCell.value = `Trller - ${user}`;
            titleCell.alignment = { horizontal: "center", vertical: "middle" };
            titleCell.font = { bold: true, size: 16 };

            worksheet.addRow(headers_thai).font = { bold: true };
            worksheet.columns = [
                { key: "เลขสัญญา", width: 25 },
                { key: "REF2", width: 25 },
                { key: "เงินที่จ่าย", width: 25 },
                { key: "วันที่จ่าย", width: 25 },
            ];
            dataSource.forEach((row) => {
                const rowData = headers_colums_name.map((column) => row[column]);
                worksheet.addRow(rowData);
            });
        };

        const headers_thai = ["เลขสัญญา", "REF2", "เงินที่จ่าย", "วันที่จ่าย"];
        const headers_colums_name = ["CONTNO", "REF2", "money", "PAYMENTDATE"];

        if (result?.length > 0) {
            addSheet("Treller", result, headers_colums_name, headers_thai);
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "data.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const testreportandexcel = async () => {
        const result = [];
        dataArray?.forEach((e) => {
            const data = {}; // สร้างวัตถุใหม่ในแต่ละรอบ
            data.CONTNO = e?.CONTNO;
            data.REF2 = e?.REF2;
            data.money = currencyFormatOne(e?.money);
            data.PAYMENTDATE = dayjs(e?.PAYMENTDATE).format("DD-MM-YYYY");

            // เพิ่มข้อมูลที่สร้างลงในอาร์เรย์
            result.push(data);
        });

        console.log("result++++++", result); // แสดงผลข้อมูลทั้งหมด
        return result; // ส่งคืนอาร์เรย์ข้อมูลทั้งหมด
    };

    const loadDatagetcts_detail = async () => {

        var data_type
        if (selectedValueType === 1) {
            data_type = 'lsfhp'
        } else if (selectedValueType === 2) {
            data_type = 'vsfhp'
        } else if (selectedValueType === 3) {
            data_type = 'rpsl'
        } else if (selectedValueType === 4) {
            data_type = 'sfhp'
        }

        const papams = {
            date: dateSearch,
            BILLCOLL: user,
            nicknameSalcod: nicknameSalcod,
            DATA_TYPE: data_type

        }

        setLoading(true)
        await axios.post(getcts_detail, papams)
            .then((res) => {
                // console.log("res.data", res.data)
                if (res.data) {
                    //เพิ่ม key ให้กับแต่ละรายการ
                    const dataWithKeys = res.data.map((item, index) => ({
                        ...item,
                        key: index, // ใช้ index เป็น key
                    }));


                    dataWithKeys.sort((a, b) => {
                        const contnoA = a.CONTNO || "";
                        const contnoB = b.CONTNO || "";

                        if (contnoA < contnoB) return -1;
                        if (contnoA > contnoB) return 1;
                        return 0; // กรณีที่เท่ากัน

                        // หาก CONTNO เท่ากัน ให้เรียงตามวันที่
                        // const dateA = new Date(a.PAYDT || a.TEMPDATE);
                        // const dateB = new Date(b.PAYDT || b.TEMPDATE);
                        // return dateB - dateA; // เรียงวันที่จากล่าสุด
                    });

                    // dataWithKeys.sort((a, b) => {
                    //     const dateA = new Date(a.PAYDT || a.TEMPDATE);
                    //     const dateB = new Date(b.PAYDT || b.TEMPDATE);
                    //     // เปรียบเทียบเพื่อเรียงจากล่าสุด
                    //     return dateB - dateA;
                    // });

                    setDataArray(dataWithKeys); // เซ็ตข้อมูลพร้อม key
                    setCheckLoadData(1)
                } else {
                    setDataArray([]); // เซ็ตข้อมูลพร้อม key
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }
    // ไม่มีทศนิยม
    const currencyFormatOnes = (amount) => {
        if (amount) {
            return Number(amount)
                .toLocaleString("en-US", { maximumFractionDigits: 0 });
        } else {
            return "0";
        }
    };
    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return 0;
        }
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onSelect = (value) => {
        console.log("value", value);
        setSelectedValueType(value);
    };
    const countoptionsType = () => {
        const options = [
            // { value: 0, label: `ทั้งหมด (${countstype[0].value})` },
            { value: 1, label: `บัญชี 1` },
            { value: 2, label: `บัญชี 2` },
            { value: 3, label: `บัญชี 3` },
            { value: 4, label: `บัญชี 8` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            width: "8%",
            render: (text, object, index) => (
                <td data-label="ลำดับ : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {index + 1}
                </td>
            ),
        },
        {
            title: "เลขสัญญา",
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: 'center',
            render: (text, record, index) => (
                <td data-label="เลขสัญญา : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.CONTNO || "ไม่มีเลขสัญญา"}
                </td>
            ),
        },
        {
            title: "REF2",
            dataIndex: "REF2",
            key: "REF2",
            align: 'center',
            render: (text, record) => (
                <td data-label="REF2 : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.REF2 || "-"}
                </td>
            ),
        },
        {
            title: "เงินที่โอนเข้ามา",
            dataIndex: "money",
            key: "money",
            align: 'center',
            render: (text, record, index) => (
                <td data-label="เงินที่โอนเข้ามา : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.money ? currencyFormatOne(record.money) + ' ' + 'บาท' : '-'}
                </td>
            ),

        },
        {
            title: "วันที่จ่ายเข้ามา",
            dataIndex: "PAYMENTDATE",
            key: "PAYMENTDATE",
            align: 'center',
            render: (text, record, index) => (
                <>
                    <td data-label="วันที่จ่ายเข้ามา : "
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px",
                            height: "100%"
                        }}
                    >
                        {record.PAYMENTDATE ? dayjs(record.PAYMENTDATE).format("DD-MM-YYYY") : '-'}
                    </td>
                </>
            ),
        },
    ];

    return (
        <Card
            style={{
                height: '100vh',
            }}
        >
            <Spin spinning={loading} size="large" tip="Loading..." >

                <Row gutter={32} justify={'center'}>
                    <Col>
                        <div className="text-center">
                            <h2>Treller</h2>
                        </div>
                    </Col>
                </Row>
                <Row justify={'center'} className='mains'>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{ remember: true, }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <aside>
                            <div style={{ display: '-ms-flexbox', lineHeight: '40px', margin: 2, textAlign: 'right' }}>
                                <Form.Item name="date">
                                    <div style={{ textAlign: 'center' }}>
                                        <span>วันที่ : </span>
                                        <DatePicker
                                            name="date"
                                            format={'YYYY-MM-DD'}
                                            defaultValue={dayBeforeCurrent} // ค่าเริ่มต้นเป็นวันที่ก่อนปัจจุบัน
                                            style={{ height: '40px', width: 250 }}
                                            disabledDate={(currentDate) => {
                                                // ปิดการเลือกวันที่ที่เป็นปัจจุบันหรือในอนาคต
                                                return currentDate && currentDate.isAfter(dayBeforeCurrent, 'day');
                                            }}
                                            onChange={(e) => {
                                                if (e) {
                                                    const selectedDate = e.toDate();
                                                    if (selectedDate >= dayCurrent.toDate()) {
                                                        message.info('ข้อมูลยังไม่ถึงวันที่ที่เลือก');
                                                    } else {
                                                        setDateSearch(e.format("YYYY-MM-DD"));
                                                    }
                                                }
                                            }}
                                        />
                                        <br />
                                        <span style={{ textAlign: 'center', color: 'red' }}>
                                            ** วันปัจจุบัน จะไม่สามารถดูข้อมูลได้ **
                                        </span>
                                    </div>
                                </Form.Item>
                            </div>
                            <div style={{ display: '-ms-flexbox', lineHeight: '40px', margin: 2, textAlign: 'right' }}>
                                <span>เลือกบัญชี : </span>
                                <Select
                                    style={{ width: 250, height: '40px' }}
                                    value={selectedValueType}
                                    onChange={onSelect}
                                >
                                    {countoptionsType()}
                                </Select>
                            </div>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button
                                    style={{ marginRight: "10px", color: 'white', height: '40px', background: '#302e94f7' }}
                                    onClick={loadDatagetcts_detail}
                                >
                                    <FaDatabase />
                                    <b>ดึงข้อมูล</b>
                                </Button>
                            </div>
                            {checkLoadData === 0 ?
                                <>
                                    <div style={{ margin: 2, textAlign: 'center' }}>
                                        <Button
                                            style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                            // onClick={exportToExcel}
                                            disabled
                                        >
                                            <FaDatabase />
                                            <b>Export Excel</b>
                                        </Button>
                                    </div>
                                    <div style={{ margin: 2, textAlign: 'center' }}>
                                        <Button
                                            style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                            // onClick={exportToPDF}
                                            disabled
                                        >
                                            <FaDatabase />
                                            <b>Export PDF</b>
                                        </Button>
                                    </div>
                                </>

                                :
                                <>
                                    <div style={{ margin: 2, textAlign: 'center' }}>
                                        <Button
                                            style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                            onClick={exportToExcel}
                                        >
                                            <FaDatabase />
                                            <b>Export Excel</b>
                                        </Button>
                                    </div>
                                    <div style={{ margin: 2, textAlign: 'center' }}>
                                        <Button
                                            style={{ marginRight: "10px", height: '40px', background: '#f9f9f9' }}
                                            onClick={exportToPDF}
                                        >
                                            <FaDatabase />
                                            <b>Export PDF</b>
                                        </Button>
                                    </div>
                                </>
                            }
                        </aside>
                    </Form>
                </Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="responsive-table-wrapper">
                        <Table
                            className="responsive-table"
                            columns={columns}
                            dataSource={dataArray}
                            scroll={{
                                y: calculateTableHeight()
                                ,
                            }}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                </Col>
            </Spin>

        </Card>
    )
}

export default MainPay