import { Button, Card, Col, Row, Spin, Table, Tag, Input, notification } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaCheckCircle, FaCheckSquare, FaEdit, FaTelegramPlane } from 'react-icons/fa';
import BillExpediteCEO from './modals/Approved';
import { SearchOutlined } from "@ant-design/icons";
import Edit_Approved from './modals/Edit_Approved';
import Approved from './modals/Approved';
import { ceo_accept, results_contno } from '../mid/all-api';

function MianOuside() {
    const [loading, setLoading] = useState(false);
    const [dataContno, setDataContno] = useState([]);
    const [modalAddAaprove, setIsModalAddAaprove] = useState(false);
    const [modalEditAaprove, setIsModalEditAaprove] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [datas, setDatas] = useState(false);
    const [arrayTable, setArrayTable] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const [scrollY, setScrollY] = useState(window.innerHeight);
    const [checkLoadData, setCheckLoadData] = useState(0);
    const [queryContno, setQueryContno] = useState('');
    const user = localStorage.getItem("username");
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        loadDataOutside();
    }, [])

    const loadDataOutside = async () => {
        setLoading(true)
        await axios.get(results_contno)
            .then((res) => {
                if (res.data) {
                    // console.log("red.data", res.data)
                    setDataContno(res.data)
                    setArrayTable(res.data)
                    setCheckLoadData(1)
                } else {
                    console.log("No red.data")
                }

            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const handleCloseModalApproved = () => {
        setIsModalAddAaprove(false);
        loadDataOutside()
    };
    const handleCloseModalEditApproved = () => {
        setIsModalEditAaprove(false);
        loadDataOutside()
    };

    const handleApprovedCeo = async (value) => {
        console.log("value", value)
        const param = {
            ...value,
            acceptBy: user
        }
        console.log("param", param)
        setLoading(true)
        await axios.post(ceo_accept, value)
            .then((res) => {
                if (res.data) {
                    // console.log("res.data", res.data)
                    successSend("top")
                    loadDataOutside()
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    // ช่องกรอก
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        console.log("inputValue", inputValue);
        setQueryContno(inputValue);
        if (inputValue === '') {
            setArrayTable(dataContno)
        }
    };
    // ฟังก์ชันจับเหตุการณ์กด Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onQuery();  // เรียกฟังก์ชันค้นหาหลังจากกด Enter
        }
    };

    const successSend = (placement) => {
        api.success({
            message: <b>รับงาน {placement?.CONTNO} สำเร็จ</b>,
            placement,
        });
    }
    const successSendAppproved = (placement) => {
        api.success({
            message: <b>ส่งเคส {placement?.CONTNO} สำเร็จ</b>,
            placement,
        });
    }
    const successSendEdit = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูล {placement?.CONTNO} สำเร็จ</b>,
            placement,
        });
    }
    const errorSend = (placement) => {
        api.error({
            message: <b>ส่งเคส {placement?.CONTNO} ไม่สำเร็จ</b>,
            placement,
        });
    }

    const onQuery = (value) => {
        console.log("value", value)
        if (queryContno === '') {
            setArrayTable(dataContno)
        }
        let lowerQuery = queryContno.toLowerCase();
        let result = dataContno.filter(
            (item) =>
                (item.CONTNO && item.CONTNO.toLowerCase().includes(lowerQuery)) || // เลขสัญญา
                (item.NAME1 && item.NAME1.toLowerCase().includes(lowerQuery)) || // ชื่อ
                (item.NAME2 && item.NAME2.toLowerCase().includes(lowerQuery)) || // นามสกุล
                (item.REGNO && item.REGNO.toLowerCase().includes(lowerQuery)) || // ทะเบีย หรือ เลขโฉนด
                (item.STRNO && item.STRNO.toLowerCase().includes(lowerQuery)) || //เลขคัสซี
                (item.TELP && item.TELP.toLowerCase().includes(lowerQuery))   //เบอร์โทร
        );
        // console.log("result", result)
        setArrayTable(result);
    };
    const handleChangeStatus = (data) => {
        // console.log("TEST", data);
        if (data.status === 2) {
            successSendAppproved("top")
        } else if (data.status === 3) {
            successSendEdit("top")
        }
    };


    const ColumsOutside = [

        {
            title: <div style={{ textAlign: "center" }}>ลำดับ</div>,
            dataIndex: "key",
            key: "key",
            align: "center",
            onCell: () => ({
                "key": "ลำดับ",
            }),
            render: (text, object, key) => key + 1,
            sorter: {
                compare: (a, b) => a.key - b.key,
                multiple: 5,
            },
        },
        {
            title: "เลขสัญญา",
            dataIndex: "NAME",
            key: "NAME",
            align: "center",
            render: (text, record) => {
                return (
                    <>
                        {record.CONTNO}<n />
                    </>
                )
            }
        },
        {
            title: `สถานะ`,
            dataIndex: "approvalStatusName",
            key: "approvalStatusName",
            align: "center",
            render: (text, record) => {
                return (
                    <>
                        {record.approvalStatus === 28 ?
                            <Tag style={{ background: '#f2b335', color: 'black', padding: 5 }}> {record.approvalStatusName}</Tag>
                            : record.approvalStatus === 2 ?
                                <Tag style={{ background: 'gray', color: 'white', padding: 5 }}> {record.approvalStatusName}</Tag>
                                : record.approvalStatus === 3 &&
                                <Tag style={{ background: 'green', color: 'white', padding: 5 }}> {record.approvalStatusName}</Tag>

                        }

                    </>
                )
            }
        },
        {
            title: `Action`,
            dataIndex: "Action",
            key: "Action",
            align: "center",
            render: (text, record) => {
                // console.log("record", record)
                return (
                    <>
                        {record.approvalStatus === 28 ?
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Button
                                        style={{ height: '40px', background: '#f2b335', color: 'black  ' }}
                                        onClick={() => {
                                            handleApprovedCeo(record)
                                            // setDatas(record);
                                        }}
                                    >
                                        <b>
                                            รับงาน
                                        </b>
                                    </Button>
                                </div>
                            </>
                            : record.approvalStatus === 2 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Button
                                        style={{ height: '40px', }}
                                        onClick={() => {
                                            setIsModalAddAaprove(true);
                                            setDatas(record);
                                        }}
                                    >
                                        <b>
                                            <FaEdit size={20} style={{ color: 'blue' }} />
                                        </b>
                                    </Button>
                                </div>
                                : record.approvalStatus === 3 && !record.flag ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Button
                                            style={{ height: '40px', background: 'green', color: 'white' }}
                                            onClick={() => {
                                                setIsModalEditAaprove(true);
                                                setDatas(record);
                                            }}
                                        >
                                            <b>
                                                <div style={{ display: 'flex' }}>
                                                    <FaCheckCircle heck size={20} style={{ color: 'white', marginRight: '10px' }} />
                                                    <span>
                                                        อนุมัติ
                                                    </span>
                                                </div>
                                            </b>
                                        </Button>
                                    </div>
                                    : record.approvalStatus === 3 && record.flag === true ?
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Button
                                                style={{ height: '40px', background: 'blue', color: 'white' }}
                                                onClick={() => {
                                                    setIsModalAddAaprove(true);
                                                    setDatas(record);
                                                }}
                                            >
                                                <b>
                                                    มีการทำเคสต่อแล้ว
                                                </b>
                                            </Button>
                                        </div>
                                        : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Button
                                                style={{ height: '40px' }}
                                                onClick={() => {
                                                    setIsModalAddAaprove(true);
                                                    setDatas(record);
                                                }}
                                            >
                                                <b>
                                                    <FaTelegramPlane size={20} style={{ color: 'blue' }} />
                                                </b>
                                            </Button>
                                        </div>

                        }
                    </>
                )
            }
        }
    ]

    const calculateTableHeight = () => {
        const headerHeight = 100; // ความสูงของ Header
        const footerHeight = isMobile ? 150 : 0; // ความสูงของ Footer (มือถือมี Pagination)
        const marginHeight = 60; // Margin ระหว่างส่วนต่างๆ
        const otherComponentsHeight = 150; // ความสูงของส่วนอื่นๆ (ปุ่ม, Select, Input ฯลฯ)

        // console.log("headerHeight", headerHeight)
        // console.log("footerHeight", footerHeight)
        // console.log("marginHeight", marginHeight)
        // console.log("otherComponentsHeight", otherComponentsHeight)

        return scrollY - (headerHeight + footerHeight + marginHeight + otherComponentsHeight);
    };




    return (
        <Card
            style={{
                height: '100vh',
            }}
        >
            <Spin spinning={loading} size="large" tip="Loading..." >

                <Row gutter={32} justify={'center'}>
                    <Col>
                        <div className="text-center">
                            <h2>ตรวจสอบข้อมูลชุดนอก</h2>
                        </div>
                    </Col>
                </Row>
                <Row
                    justify={'center'}
                    className="mains"
                    align="middle"
                    style={{ textAlign: 'center', width: '100%' }} // เพิ่มการจัดกึ่งกลาง
                >
                    <div style={{ margin: 2, textAlign: 'center' }}>
                        <span>กรอกเลขสัญญา : </span>
                        {checkLoadData === 0 ?
                            <>
                                <Input
                                    disabled
                                    style={{ width: "280px", height: '40px' }}
                                    placeholder="ค้นหาเลขที่สัญญา"
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    name="search"
                                    id="search"
                                />
                            </>
                            :
                            <>
                                <Input
                                    style={{ width: "280px", height: '40px' }}
                                    placeholder="ค้นหาเลขที่สัญญา"
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    name="search"
                                    id="search"
                                />
                            </>
                        }
                    </div>
                    <div style={{ margin: 2, textAlign: 'center' }}>
                        <Button
                            style={{ marginRight: "10px", height: '40px', width: "50px" }}
                            onClick={(e) => onQuery(e)}
                        >
                            <SearchOutlined />
                        </Button>
                    </div>

                </Row>
                <Col
                    justify={'center'}
                    className="mains"
                    align="middle"
                    style={{ textAlign: 'center', width: '100%', marginTop: 5 }} // เพิ่มการจัดกึ่งกลาง
                >
                    <div className="responsive-table-wrapper">
                        <Table
                            className="responsive-table"
                            columns={ColumsOutside}
                            dataSource={arrayTable}
                            scroll={{
                                y: calculateTableHeight(),
                            }}
                        // pagination={{ pageSize: 10 }}
                        />
                    </div>
                </Col>
            </Spin>
            {
                modalAddAaprove ?
                    <Approved
                        open={modalAddAaprove}
                        close={setIsModalAddAaprove}
                        continoBill={datas.CONTNO}
                        data={datas}
                        handleChangeStatus={handleChangeStatus}
                        handleCloseModal={handleCloseModalApproved}
                    />
                    : null
            }
            {
                modalEditAaprove ?
                    <Edit_Approved
                        open={modalEditAaprove}
                        close={setIsModalEditAaprove}
                        continoBill={datas.CONTNO}
                        data={datas}
                        handleChangeStatus={handleChangeStatus}
                        handleCloseModal={handleCloseModalEditApproved}

                    />
                    : null
            }
            {contextHolder}
        </Card>

    )

}

export default MianOuside