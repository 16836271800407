import { Card, Spin, Row, Col, Table, Select, Button, message, Input, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from "@ant-design/icons";
import { FaDatabase, FaSearch, FaTelegramPlane } from 'react-icons/fa';
import axios from 'axios';
import { results, results_contno } from '../mid/all-api';
import BillExpediteSendOutside from './modals/BillExpediteSendOutside';

function MainSend_Outside() {

    const [loading, setLoading] = useState(false);
    const [modalAddAaprove, setIsModalAddAaprove] = useState(false);
    const [datas, setDatas] = useState(false);
    const [dataValue, setDataValue] = useState();

    const [arrayTable, setArrayTable] = useState();
    const [dataArr, setDataArr] = useState([]);
    const [dataContno, setDataContno] = useState([]);
    const [checkLoadData, setCheckLoadData] = useState(0);

    const user = localStorage.getItem('username')
    const nicknameSalcod = localStorage.getItem('nicknameSalcod')
    const [isMobile, setIsMobile] = useState(false);
    const [scrollY, setScrollY] = useState(window.innerHeight);

    const [selectedValue, setSelectedValue] = React.useState(0);
    const [queryContno, setQueryContno] = useState('');
    const [changecheck, setChangeCheck] = useState()
    const [selectedValueType, setSelectedValueType] = React.useState(1);

    useEffect(() => {
        loadDataOutside();
    }, [queryContno])

    const loadDataOutside = async () => {
        setLoading(true)
        await axios.get(results_contno)
            .then((res) => {
                if (res.data) {
                    console.log("red.data", res.data)
                    setDataContno(res.data)
                    setChangeCheck(res.data)
                } else {
                    console.log("No red.data")
                }

            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const countoptionsType = () => {
        const options = [
            // { value: 0, label: `ทั้งหมด (${countstype[0]?.value ?? 0})` },
            { value: 1, label: `บัญชี 1` },
            { value: 2, label: `บัญชี 2` },
            { value: 3, label: `บัญชี 3` },
            { value: 4, label: `บัญชี 8` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }

    const handleCloseModal = () => {
        setIsModalAddAaprove(false);
        loadDataOutside()
    };

    const onSelectType = (value) => {
        console.log("valurrereee", value)
        setSelectedValueType(value);
        setSelectedValue("")
    };

    const handleChangeStatus = (data, status) => {
        setDataValue(status)
        console.log("TEST", data, status);
        const result = dataArr.map((item) => {
            if (item.CONTNO === data.CONTNO) {
                return { ...data };
            } else {
                return { ...item };
            }
        });
        setDataArr(result);
        // handleStatusApproval(result);
        if (status === "reset") {
            setArrayTable(result);
        } else {
            const arr = result.filter((item) =>
                item.ApprovalStatus === status
            );
            setArrayTable(arr);

        }
    };
    const calculateTableHeight = () => {
        const headerHeight = 100; // ความสูงของ Header
        const footerHeight = isMobile ? 150 : 0; // ความสูงของ Footer (มือถือมี Pagination)
        const marginHeight = 60; // Margin ระหว่างส่วนต่างๆ
        const otherComponentsHeight = 150; // ความสูงของส่วนอื่นๆ (ปุ่ม, Select, Input ฯลฯ)

        // console.log("headerHeight", headerHeight)
        // console.log("footerHeight", footerHeight)
        // console.log("marginHeight", marginHeight)
        // console.log("otherComponentsHeight", otherComponentsHeight)

        return scrollY - (headerHeight + footerHeight + marginHeight + otherComponentsHeight);
    };


    const onQueryContno = async () => {
        setQueryContno("")

        var data_type
        if (selectedValueType === 1) {
            data_type = 'lsfhp'
        } else if (selectedValueType === 2) {
            data_type = 'vsfhp'
        } else if (selectedValueType === 3) {
            data_type = 'rpsl'
        } else if (selectedValueType === 4) {
            data_type = 'sfhp'
        }
        // { DATA_TYPE: 'rpsl' }, 3
        // { DATA_TYPE: 'vsfhp' }, 2
        // { DATA_TYPE: 'lsfhp' }, 1
        // { DATA_TYPE: 'sfhp' }, 8

        const papams = {
            BILLCOLL: user,
            nicknameSalcod: nicknameSalcod,
            DATA_TYPE: data_type

        }
        setLoading(true);
        await axios.post(results, papams)
            .then(res => {
                let i = 1;
                // console.log("res.data", res.data)
                if (res.data) {
                    const newData = res.data.map((item) => ({
                        ...item,
                        key: i++,
                    }));

                    let NocloseAun = res.data.filter((item,) => !item.flagClose && !item.flagTellAun);



                    // ใช้ Map เพื่อเก็บข้อมูลตาม CONTNO เพื่อรวมข้อมูลที่ซ้ำกัน
                    const contnoMap = new Map();
                    NocloseAun.forEach((ii) => {
                        const matchingData = dataContno.find((items) => items.CONTNO === ii.CONTNO); // หา CONTNO ที่ตรงกัน
                        const combinedData = matchingData
                            ? { ...ii, ...matchingData, dataList: ii.dataList || [] } // รวมข้อมูลถ้าพบ
                            : { ...ii, dataList: ii.dataList || [] }; // ถ้าไม่พบ matchingData

                        if (contnoMap.has(ii.CONTNO)) {
                            // ถ้ามี CONTNO ใน Map อยู่แล้ว ให้รวมข้อมูล dataList
                            const existingData = contnoMap.get(ii.CONTNO);
                            contnoMap.set(ii.CONTNO, {
                                ...existingData,
                                ...combinedData, // อัปเดตข้อมูลใหม่
                                dataList: [...(existingData.dataList || []), ...(combinedData.dataList || [])], // รวม dataList
                            });
                        } else {
                            // ถ้ายังไม่มีใน Map ให้เพิ่มใหม่
                            contnoMap.set(ii.CONTNO, combinedData);
                        }
                    });

                    // แปลงข้อมูลจาก Map เป็น Array
                    const expandedData = Array.from(contnoMap.values());

                    console.log("expandedData", expandedData);

                    // NocloseAun.forEach((ii, index) => {
                    //     dataContno.forEach((items) => {
                    //         if (ii.CONTNO === items.CONTNO) {
                    //             // ข้อมูลมี CONTNO ตรงกัน
                    //             if (ii?.dataList && ii.dataList.length >= 1) {
                    //                 console.log("รวม0")
                    //                 ii.dataList.forEach((listData, listIndex) => {
                    //                     const key = `${ii.CONTNO}-${listIndex}`;
                    //                     if (!contnoMap.has(key)) {
                    //                         contnoMap.set(key, {
                    //                             ...ii,
                    //                             ...listData,
                    //                             ...items,
                    //                             key, // กำหนด key เฉพาะ
                    //                         });
                    //                     }
                    //                 });
                    //             } else {
                    //                 console.log("รวม1")
                    //                 const key = `${ii.CONTNO}`;
                    //                 if (!contnoMap.has(key)) {
                    //                     contnoMap.set(key, {
                    //                         ...ii,
                    //                         ...items,
                    //                         key, // กำหนด key เฉพาะ
                    //                     });
                    //                 }
                    //             }
                    //         } else {
                    //             // ข้อมูลที่ไม่มี CONTNO ตรงกัน
                    //             const key = `${ii.CONTNO || 'unknown'}-${index}`;
                    //             if (!contnoMap.has(key)) {
                    //                 contnoMap.set(key, {
                    //                     ...ii,
                    //                     ...items,
                    //                     key, // กำหนด key เฉพาะ
                    //                 });
                    //             }
                    //         }
                    //     });
                    // });

                    // แปลงข้อมูลจาก Map เป็น Array
                    // expandedData.push(...contnoMap.values());






                    // NocloseAun.forEach((ii, index) => {
                    //     if (ii?.dataList && ii.dataList.length >= 1) {
                    //         ii.dataList.forEach((listData, listIndex) => {
                    //             expandedData.push({
                    //                 ...ii,
                    //                 ...listData,
                    //                 key: `${ii.CONTNO || 'unknown'}-${index}-${listIndex}` // สร้าง key เฉพาะ
                    //             });
                    //         });
                    //     } else {
                    //         expandedData.push({
                    //             ...ii,
                    //             key: `${ii.CONTNO || 'unknown'}-${index}`, // กำหนด key เฉพาะสำหรับ ii ที่ไม่มี dataList
                    //         });
                    //     }
                    // });


                    // เรียงวันที่
                    expandedData.sort((a, b) => {
                        const contnoA = a.CONTNO || "";
                        const contnoB = b.CONTNO || "";

                        if (contnoA < contnoB) return -1;
                        if (contnoA > contnoB) return 1;
                        return 0; // กรณีที่เท่ากัน


                    });

                    setArrayTable(expandedData);
                    setCheckLoadData(1)
                    setDataArr(expandedData)
                    setSelectedValue(0);
                    setLoading(false);
                } else {
                    setArrayTable([]);
                    message.error("ไม่มีเลขที่สัญญาที่ค้นหา");
                }
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };


    // ช่องกรอก
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        console.log("inputValue", inputValue);
        setQueryContno(inputValue);
        if (inputValue === '') {
            setArrayTable(changecheck)
        }
    };
    // ฟังก์ชันจับเหตุการณ์กด Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onQuery();  // เรียกฟังก์ชันค้นหาหลังจากกด Enter
        }
    };

    const onQuery = (value) => {
        console.log("value", value)
        if (queryContno === '') {
            setArrayTable(dataArr)
        }
        let lowerQuery = queryContno.toLowerCase();
        let result = dataArr.filter(
            (item) =>
                (item.CONTNO && item.CONTNO.toLowerCase().includes(lowerQuery)) || // เลขสัญญา
                (item.NAME1 && item.NAME1.toLowerCase().includes(lowerQuery)) || // ชื่อ
                (item.NAME2 && item.NAME2.toLowerCase().includes(lowerQuery)) || // นามสกุล
                (item.REGNO && item.REGNO.toLowerCase().includes(lowerQuery)) || // ทะเบีย หรือ เลขโฉนด
                (item.STRNO && item.STRNO.toLowerCase().includes(lowerQuery)) || //เลขคัสซี
                (item.TELP && item.TELP.toLowerCase().includes(lowerQuery))   //เบอร์โทร
        );
        console.log("result", result)
        setArrayTable(result);
    };

    const ColumsOutside = [

        {
            title: <div style={{ textAlign: "center" }}>ลำดับ</div>,
            dataIndex: "key",
            key: "key",
            align: "center",
            onCell: () => ({
                "key": "ลำดับ",
            }),
            render: (text, object, key) => key + 1,
            sorter: {
                compare: (a, b) => a.key - b.key,
                multiple: 5,
            },
        },
        {
            title: "เลขสัญญา",
            dataIndex: "NAME",
            key: "NAME",
            align: "center",
            render: (text, record) => {
                return (
                    <>
                        {record.CONTNO}<n />
                    </>
                )
            }
        },
        {
            title: `ชื่อ`,
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: "center",
            render: (text, record) => {
                return (
                    <>
                        {`${record.SNAM} ${record.NAME1} ${record.NAME2}`}
                    </>
                )
            }
        },
        {
            title: `สถานะ`,
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (text, record) => {
                // console.log("record", record)
                return (
                    <>
                        {record.approvalStatus === 28 ?
                            <Tag style={{ color: 'green' }}> {record.approvalStatusName}</Tag>
                            : record.approvalStatus === 2 ?
                                <Tag style={{ background: 'yellow', color: 'black' }}> {record.approvalStatusName}</Tag>
                                : record.approvalStatus === 3 ?
                                    <Tag style={{ color: 'green' }}> {record.approvalStatusName}</Tag>
                                    : <Tag style={{ color: 'black' }}> ยังไม่ได้ส่งเคส</Tag>
                        }


                    </>
                )
            }
        },
        {
            title: `Action`,
            dataIndex: "Action",
            key: "Action",
            align: "center",
            render: (text, record) => {
                // console.log("record", record)
                return (
                    <>
                        {record.approvalStatus === 28 ||
                            record.approvalStatus === 2 ||
                            record.approvalStatus === 3
                            ?
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    {/* <Tag style={{ color: 'green' }}>เสนอเคสแล้ว</Tag> */}
                                    <Button
                                        style={{ height: '40px' }}
                                        onClick={() => {
                                            setIsModalAddAaprove(true);
                                            setDatas(record);
                                        }}
                                    >
                                        <b>
                                            <FaSearch />
                                        </b>
                                    </Button>
                                </div>
                            </>
                            : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Button
                                    style={{ height: '40px' }}
                                    onClick={() => {
                                        setIsModalAddAaprove(true);
                                        setDatas(record);
                                    }}
                                >
                                    <b>
                                        <FaTelegramPlane size={20} style={{ color: 'blue' }} />
                                    </b>
                                </Button>
                            </div>
                        }
                    </>
                )
            }
        }
    ]

    return (

        <Card
            style={{
                height: '100vh',
            }}
        >
            <Spin spinning={loading} size="large" tip="Loading..." >

                <Row gutter={32} justify={'center'}>
                    <Col>
                        <div className="text-center">
                            <h2>ส่งชุดนอก</h2>
                        </div>
                    </Col>
                </Row>
                <Row justify={'center'} className='mains'>
                    <div className="responsive-table">
                        <Row justify={'center'} style={{ marginBottom: 10 }}>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <span>เลือกบัญชี : </span>
                                <Select
                                    // defaultValue={}
                                    style={{
                                        width: 280,
                                        height: '40px'
                                    }}
                                    value={selectedValueType}
                                    onChange={onSelectType}
                                >
                                    {countoptionsType()}
                                </Select>
                            </div>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button
                                    style={{ marginRight: "10px", height: '40px', color: 'white', background: '#302e94f7' }}
                                    onClick={onQueryContno}
                                >
                                    <FaDatabase />
                                    <b>ดึงข้อมูล</b>
                                </Button>
                            </div>
                        </Row>
                        <Row
                            justify={'center'}
                            className="mains"
                            align="middle"
                            style={{ textAlign: 'center', width: '100%' }} // เพิ่มการจัดกึ่งกลาง
                        >
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <span>กรอกเลขสัญญา : </span>
                                {checkLoadData === 0 ?
                                    <>
                                        <Input
                                            disabled
                                            style={{ width: "280px", height: '40px' }}
                                            placeholder="ค้นหาเลขที่สัญญา"
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            name="search"
                                            id="search"
                                        />
                                    </>
                                    :
                                    <>
                                        <Input
                                            style={{ width: "280px", height: '40px' }}
                                            placeholder="ค้นหาเลขที่สัญญา"
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            name="search"
                                            id="search"
                                        />
                                    </>
                                }
                            </div>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button
                                    style={{ marginRight: "10px", height: '40px', width: "50px" }}
                                    onClick={(e) => onQuery(e)}
                                >
                                    <SearchOutlined />
                                </Button>
                            </div>

                        </Row>
                    </div>
                </Row>
                <Col>
                    <div className="responsive-table-wrapper">
                        <Table
                            className="responsive-table"
                            columns={ColumsOutside}
                            dataSource={arrayTable}
                            scroll={{
                                y: calculateTableHeight(),
                            }}
                        // pagination={{ pageSize: 10 }}
                        />
                    </div>
                </Col>
            </Spin>
            {
                modalAddAaprove ?
                    <BillExpediteSendOutside
                        open={modalAddAaprove}
                        close={setIsModalAddAaprove}
                        continoBill={datas.CONTNO}
                        data={datas}
                        onChangeStatus={handleChangeStatus}
                        status={selectedValue}
                        handleCloseModal={handleCloseModal}
                        onSearch={selectedValueType}
                    />
                    : null
            }

        </Card>

    )
}

export default MainSend_Outside