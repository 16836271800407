import React, { useState, useEffect, useRef } from "react";
import { Modal, Divider, Row, Col, FloatButton, Form, message, Spin, Card, Space, Button, Radio, Checkbox, notification } from "antd";
import {
  DownloadOutlined,
  CloseOutlined,
  ProfileOutlined,
  RetweetOutlined,
  ExclamationCircleFilled,
  CreditCardOutlined,
} from "@ant-design/icons";
import axios from "axios";
import logo from "../../../assets/Images/logo.png";
import ModalInstallment from "../../check_info_PG_RE/modals/ModalInstallment";
import "../../css/MobileActive.css";
import html2canvas from "html2canvas";
import QRCodeWithContractNo from "./QRCodeWithContractNo";
import moment from "moment";
import { FaCcAmazonPay, FaCloudDownloadAlt, FaPaperPlane, FaTasks, FaTimes } from "react-icons/fa";
import { edit_ceo_accept, exdept } from "../../mid/all-api";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

require("moment/locale/th");

const Edit_Approved = ({ open, close, continoBill, data, handleChangeStatus, handleCloseModal, status }) => {
  const [form] = Form.useForm()
  const [dataGuarantor, setDataGuarantor] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  const [dataCalRush, setDataCalRush] = useState([]);
  const [isModalInstallment, setIsModalInstallment] = useState(false);
  const [isModalQrCode, setModalQrCode] = useState(false);
  const [continoBills, setContinoBills] = useState();
  const elementRef = useRef(null);
  const user = localStorage.getItem("username");
  const idPosition = localStorage.getItem("idSep");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataAcc, setDataAcc] = useState();
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (data) {
      console.log("data:", data); // ตรวจสอบค่า data
      if (data.ConOutSidetoCEO && data.ConOutSidetoCEO.id === 1) {
        console.log("1", data.ConOutSidetoCEO.id)
        form.setFieldsValue({ conditionsed: data.ConOutSidetoCEO.id });
        setDataAcc(data.ConOutSidetoCEO.id)
      } else {
        console.log("2", data.ConOutSidetoCEO.id)
        form.setFieldsValue({ conditionsed: data.ConOutSidetoCEO.id });
        setDataAcc(data.ConOutSidetoCEO.id)
      }
    }
  }, []);


  // ถ้าหากไม่มีการเลือกอของเฮีย จะเอาข้อมูลที่ดึงมาจากฐายข้อมูลเก็บเข้า column ceo_select ที่ตาราง outside_information
  const handleRadioChange = (e) => {
    setDataAcc(e.target.value)
  }

  const loadData = async () => {
    setLoading(true); // เริ่มโหลด
    await axios.post(exdept, { DATA_TYPE: data?.DATA_TYPE, CONTNO: data?.CONTNO })
      .then(res => {
        if (res.data) {
          console.log("res.data", res.data);
          setDataGuarantor(res.data.findFollowGuarantors || []);
          setDataInfo(res.data.findInfo || []);
          setDataCalRush(res.data.findCalRush || []);

        } else {
          setDataGuarantor([]);
          setDataInfo([]);
          setDataCalRush([]);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const errorSend = (placement) => {
    api.error({
      message: <b>ส่งเคส {data?.CONTNO} ไม่สำเร็จ</b>,
      placement,
    });
  }

  const handleSubmit = async () => {
    setLoading(true)
    console.log("dataAcc", dataAcc)
    delete data.ConOutSidetoCEO
    delete data.notesOutSidetoCEO
    const info = {
      ...data,
      conditions: dataAcc
    }
    console.log("info", info)
    await axios.post(edit_ceo_accept, info)
      .then((res) => {
        if (res.data) {
          // console.log("red.data", res.data)
          handleChangeStatus({ ...data, status: 3 });
          handleClose()
        } else {
          console.log("No red.data")
        }

      }).catch((err) => {
        console.log("err", err)
        errorSend("top")
        setLoading(false)
      })
  }

  const handleClose = () => {
    setIsModalOpen(false);
    close(false);
  };


  const showConfirmSave = () => {
    confirm({
      title: "คำร้องขอบันทึกรูปภาพ",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการบันทึกรูปภาพใช่หรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        htmlToImageConvert();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showQrCode = () => {
    confirm({
      title: "คำร้องเปิด Qr Code",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการจะสร้าง Qr code ชำระเงินใช่หรือไม่",
      okText: "ต้องการ",
      cancelText: "ยกเลิก",
      onOk() {
        setTimeout(() => {
          setContinoBills(continoBill); // ตั้งค่าข้อมูล QR Code
          setModalQrCode(true); // แสดง QR Code Modal
        }, 500); // ระยะเวลาในการโหลด (2 วินาที)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const currentDateTime = () => {
    const date = moment().add(543, "year").format("LLL");
    return date;
  };

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return 0;
    }
  };

  const htmlToImageConvert = () => {
    html2canvas(elementRef.current, { cacheBust: false })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File(
              [blob],
              `การ์ดลูกค้าสัญญาเลขที่ ${continoBill} ${currentDateTime()}.png`,
              { type: "image/png" }
            );
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = file.name;
            document.body.appendChild(link); // เพิ่มลิงก์ใน DOM
            link.click();
            document.body.removeChild(link); // ลบลิงก์ออกจาก DOM
            message.success(`บันทึกรูปภาพสำเร็จ ${continoBill}`);
          } else {
            throw new Error("Blob is null");
          }
        }, "image/png"); // ใช้ MIME type เป็น 'image/png'
      })
      .catch((err) => {
        console.log("เกิดข้อผิดพลาด ", err);
        message.error("ยกเลิกการบันทึกภาพ");
      });
  };

  const dataGuarantors = ({ item, index }) => {
    return (
      <>
        <div label="ผู้ค้ำที่" style={{ marginTop: "10px" }}>
          {" "}
          <b>ผู้ค้ำที่ {index + 1}: </b>
          {item.NAME ? item.NAME : "-"}
        </div>
        <div
          label="ที่อยู่ผู้ค้ำ"
          style={{ margin: 0, textWrap: "break-word" }}
        >
          {" "}
          <b>ที่อยู่ผู้ค้ำที่ {index + 1}: </b>
          {item.ADDRES ? item.ADDRES : "-"} {item.SOI ? item.SOI : "-"}{" "}
          {item.TUMB ? item.TUMB : "-"} {item.AUMP ? item.AUMP : "-"}{" "}
          {item.PROVDES ? item.PROVDES : "-"} {item.ZIP ? item.ZIP : "-"} <br />
        </div>
        <div label="เบอร์โทร" style={{ margin: 0 }}>
          {" "}
          <b>เบอร์โทร : </b>
          {item.TELP ? item.TELP : "-"}
        </div>
      </>
    );
  };
  const dataFollow = ({ item, index }) => {
    // console.log("item", item)
    if (data.DATA_TYPE === item.DATA_TYPE) {
      return (
        <>
          <div>
            <u>
              {
                item?.DATA_TYPE === "vsfhp" ||
                  item?.DATA_TYPE === "sfhp" ||
                  item?.DATA_TYPE === "lsfhp"
                  ? <b>เบี้ยปรับ : </b>
                  :
                  <b>ค่าทวงถาม : </b>
              }
              {currencyFormatOne(item?.FOLLOW)} {" "}
              บาท
            </u>
          </div>
        </>
      );
    }
  };
  const dataCal = ({ item, index }) => {
    if (data.DATA_TYPE === item.DATA_TYPE) {
      return (
        <>
          {
            item?.OTHR !== 0 ?
              <div label="ลูกหนี้อื่น ๆ" >
                <b>ลูกหนี้อื่น ๆ : </b>
                {currencyFormatOne(item.OTHR)} บาท
              </div>
              :
              <div label="ลูกหนี้อื่น ๆ">
                <b>ลูกหนี้อื่น ๆ : </b>
                {currencyFormatOne(0)} บาท
              </div>
          }
        </>
      )
    }
  }

  return (
    <Modal
      title="แก้ไขเอกสารติดตามงวด"
      open={open}
      onCancel={handleClose}
      width={850}
      footer={[null]}
    >

      {/* <FloatButton.Group
        trigger={open || "click"} // fallback เป็น "click"
        open={open ?? false} // fallback เป็น false
        // trigger="click"
        // open={open}
        style={{ right: 14 }}
      >
        <FloatButton
          icon={<FaTasks size={20} />}
          title="ตารางการผ่อน"
          style={{ boxShadow: "0 4px 8px" }}
          onClick={() => {
            setIsModalInstallment(true);
            console.log("ตารางผ่อน 2 -->", continoBill);
          }}
        ></FloatButton>
        <FloatButton
          icon={<FaCloudDownloadAlt size={20} />}
          title="บันทึกรูปภาพ"
          style={{ boxShadow: "0 4px 8px" }}
          onClick={() => {
            showConfirmSave();
          }}
        />
        <FloatButton
          icon={<FaCcAmazonPay size={20} />}
          title="ชำระเงิน"
          style={{ boxShadow: "0 4px 8px" }}
          onClick={showQrCode}
        />
        <FloatButton
          icon={<FaTimes size={20} />}
          style={{ boxShadow: "0 4px 8px" }}
          title="ปิด"
          onClick={() => {
            handleClose();
            console.log("ปิด modal");
          }}
        ></FloatButton>
      </FloatButton.Group> */}
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off">
        {
          loading ?
            <>
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // ใช้เต็มความสูงของหน้าจอ
              }}>
                <Spin size="large" tip="Loading...">
                  <div>Content is loading...</div>
                </Spin>
              </div>
            </>
            :
            <>
              {Array.isArray(dataInfo) && dataInfo.length > 0 ? (
                <>

                  {dataInfo?.length > 1 ? (
                    <>
                      {dataInfo.slice(0, 1).map((item, index) => (
                        <div key={index}>
                          <div
                            key={item?.CONTNO ? item?.CONTNO : Math.random()}
                            ref={elementRef}
                            className="center"
                            style={{
                              // backgroundColor: "transparent", // กำหนดสีพื้นหลัง
                              margin: "0 auto",
                              border: "1px solid #ccc", // กำหนดเส้นขอบ
                              fontFamily: "Arial, Helvetica, sans-serif",
                              borderCollapse: "collapse",
                            }}
                          >
                            <Col span={24} style={{ textAlign: 'center' }}>
                              <h5 style={{ opacity: 0.5 }}>{currentDateTime()}</h5>
                            </Col>
                            <Col span={24} className="main1">
                              <aside style={{ alignItems: 'center', textAlign: 'center', marginBottom: '5px', fontSize: '18px' }}>
                                <div>
                                  <img width={"60%"} style={{ borderRadius: '10px' }} src={logo} alt="Logo" />
                                </div>
                                <div>
                                  <div label="เกรด" style={{ marginRight: "5px", color: "red" }}>
                                    <b>เกรด : {item?.GRDCOD}</b>
                                  </div>
                                  <div label="วันที่ทำสัญญา" style={{ textAlign: "center" }}>
                                    <span>
                                      <b>วันที่ทำสัญญา : </b>
                                    </span>
                                    {!item?.SDATE ? "-" : moment(item?.SDATE).add(543, "year").format("DD/MM/YYYY ")}
                                  </div>
                                  <div label="เลขที่สัญญา" style={{ textAlign: "center" }}>
                                    <span>
                                      <b>เลขที่สัญญา : </b>
                                    </span>
                                    {!item?.CONTNO ? "-" : item?.CONTNO}
                                  </div>
                                </div>
                              </aside>
                            </Col>
                            <Divider />
                            <Row className="main">
                              {item?.products === "land" ? (
                                <>
                                  <Row justify={'center'} style={{ width: '100%' }}>
                                    <Col span={22} style={{ textAlign: 'left' }}>
                                      <div style={{ width: '100%' }}>
                                        <div label="ชื่อผู้ทำสัญญา">
                                          {" "}
                                          <b>ชื่อผู้ทำสัญญา : </b>
                                          {!item?.CNAME ? "-" : item?.CNAME}
                                        </div>
                                        <div label="ที่อยู่ผู้ทำสัญญา">
                                          {" "}
                                          <b>ที่อยู่ผู้ทำสัญญา : </b>
                                          {!item?.ADDRESS ? "-" : item?.ADDRESS}{" "}
                                          {!item?.TUMB ? "-" : item?.TUMB}{" "}
                                          {!item?.AUMP ? "-" : item?.AUMP}{" "}
                                          {!item?.PROVDES ? "-" : item?.PROVDES}{" "}
                                          {!item?.ZIP ? "-" : item?.ZIP}
                                        </div>
                                        <div label="เบอร์โทรผู้ทำสัญญา">
                                          {" "}
                                          <b>เบอร์โทรผู้ทำสัญญา : </b>
                                          {!item?.TELP ? "-" : item?.TELP}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Divider orientation="center" style={{ marginTop: "10px" }}>
                                    <b>รายละเอียดคนค้ำ</b>
                                  </Divider>
                                  <Row justify={'center'} style={{ width: '100%' }}>
                                    <Col span={22}>
                                      <aside>
                                        {dataGuarantor?.map((item, index) => {
                                          return (
                                            <React.Fragment key={`${item.id}-${index}`}>
                                              {dataGuarantors({ item, index })}
                                            </React.Fragment>
                                          );
                                          // return dataGuarantors({ item, index, key: `${item} - ${index}` });
                                        })}

                                        {/* {guarantor_data?.map((item, index) => {
                                        return (
                                          <React.Fragment key={`${item.id}-${index}`}>
                                            {renderGuarantors({ item, index })}
                                          </React.Fragment>
                                        );
                                      })} */}
                                        <div label="ชื่อผู้ขาย" style={{ marginTop: "10px" }}>
                                          {" "}
                                          <b>ชื่อผู้ขาย : </b>
                                          {!item?.SALCOD ? "-" : item?.SALCOD}
                                        </div>

                                      </aside>
                                    </Col>
                                  </Row>
                                  <Row justify={'center'} style={{ width: '100%' }}>
                                    <Divider orientation="center" style={{ margin: 0 }}>
                                      <b>รายละเอียดที่ดิน</b>
                                    </Divider>
                                    <Col span={22} style={{ textAlign: 'left' }}>
                                      <aside>
                                        <div label="ตำบล">
                                          {" "}
                                          <b>ตำบล : </b>
                                          {!item?.MODEL ? "-" : item?.MODEL}
                                        </div>
                                        <div label="อำเภอ">
                                          {" "}
                                          <b>อำเภอ : </b>
                                          {!item?.BAAB ? "-" : item?.BAAB}
                                        </div>
                                        <div label="จังหวัด">
                                          {" "}
                                          <b>จังหวัด : </b>
                                          {!item?.TYPE ? "-" : item?.TYPE}
                                        </div>
                                        <div label="ประเภทที่ดิน">
                                          {" "}
                                          <b>ประเภทที่ดิน : </b>
                                          {!item?.COLOR ? "-" : item?.COLOR}
                                        </div>
                                        <div label="เลขที่ดิน">
                                          {" "}
                                          <b>เลขที่ดิน : </b>
                                          {!item?.REGNO ? "-" : item?.REGNO}
                                        </div>
                                        <div label="เลขโฉนดที่ดิน">
                                          {" "}
                                          <b>เลขโฉนดที่ดิน : </b>
                                          {!item?.STRNO ? "-" : item?.STRNO}
                                        </div>
                                      </aside>
                                    </Col>
                                  </Row>

                                </>
                              ) : (
                                <>
                                  <Row justify={'center'} style={{ width: '100%' }}>
                                    <Col span={22} style={{ textAlign: 'left' }}>
                                      <div style={{ width: '100%' }}>
                                        <div label="ชื่อผู้ทำสัญญา">
                                          {" "}
                                          <b>ชื่อผู้ทำสัญญา : </b>
                                          {!item?.CNAME ? "-" : item?.CNAME}
                                        </div>
                                        <div label="ที่อยู่ผู้ทำสัญญา">
                                          {" "}
                                          <b>ที่อยู่ผู้ทำสัญญา : </b>
                                          {!item?.ADDRESS ? "-" : item?.ADDRESS}{" "}
                                          {!item?.TUMB ? "-" : item?.TUMB}{" "}
                                          {!item?.AUMP ? "-" : item?.AUMP}{" "}
                                          {!item?.PROVDES ? "-" : item?.PROVDES}{" "}
                                          {!item?.ZIP ? "-" : item?.ZIP}
                                        </div>
                                        <div label="เบอร์โทรผู้ทำสัญญา">
                                          {" "}
                                          <b>เบอร์โทรผู้ทำสัญญา : </b>
                                          {!item?.TELP ? "-" : item?.TELP}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Divider orientation="center" style={{ marginTop: "10px" }}>
                                    <b>รายละเอียดคนค้ำ</b>
                                  </Divider>
                                  <Row justify={'center'} style={{ width: '100%' }}>
                                    <Col span={22}>
                                      <aside>
                                        {dataGuarantor?.map((item, index) => {
                                          return (
                                            <React.Fragment key={`${item.id}-${index}`}>
                                              {dataGuarantors({ item, index })}
                                            </React.Fragment>
                                          );
                                          // return dataGuarantors({ item, index, key: `${item} - ${index}` });
                                        })}
                                        <div style={{ marginTop: "10px" }}>
                                          {" "}
                                          <b>ชื่อผู้ขาย : </b>
                                          {!item?.SALCOD ? "-" : item?.SALCOD}
                                        </div>
                                      </aside>
                                    </Col>
                                  </Row>
                                  <Row justify={'center'} style={{ width: '100%' }}>
                                    <Divider orientation="center" style={{ margin: 0 }}>
                                      <b>รายละเอียดรถ</b>
                                    </Divider>
                                    <Col span={22} style={{ textAlign: 'left' }}>
                                      <aside>
                                        <div label="ยี่ห้อ">
                                          {" "}
                                          <b>ยี่ห้อ : </b>
                                          {!item?.TYPE ? "-" : item?.TYPE}
                                        </div>
                                        <div label="รุ่น">
                                          {" "}
                                          <b>รุ่น : </b>
                                          {!item?.MODEL ? "-" : item?.MODEL}
                                        </div>
                                        <div label="สี">
                                          {" "}
                                          <b>สี : </b>
                                          {!item?.COLOR ? "-" : item?.COLOR}
                                        </div>
                                        <div label="ชนิดรถ">
                                          {" "}
                                          <b>ชนิดรถ : </b>
                                          {!item?.BAAB ? "-" : item?.BAAB}
                                        </div>
                                        <div label="ทะเบียน">
                                          {" "}
                                          <b>ทะเบียน : </b>
                                          {!item?.REGNO ? "-" : item?.REGNO}
                                        </div>
                                        <div label="ปีรถ">
                                          {" "}
                                          <b>ปีรถ : </b>
                                          {!item?.MANUYR ? "-" : item?.MANUYR}
                                        </div>
                                        <div label="เลขตัวถังรถ">
                                          {" "}
                                          <b>เลขตัวถังรถ : </b>
                                          {!item?.STRNO ? "-" : item?.STRNO}
                                        </div>

                                      </aside>
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <Divider orientation="center">
                                <b>รายละเอียดสัญญา</b>
                              </Divider>
                              <Row justify={'center'} style={{ width: '100%' }}>
                                <Col span={24} style={{ textAlign: 'left' }}>
                                  <aside>
                                    <div>
                                      <b>ราคาขาย : </b>
                                      {/* TOTPRC  ราคาขาย */}
                                      {currencyFormatOne(item?.TOTPRC)} บาท
                                    </div>
                                    <div >
                                      <b>ชำระเงินดาว : </b>
                                      {/* TOTDWN  ชำระเงินดาว */}
                                      {currencyFormatOne(item?.TOTDWN)} บาท
                                    </div>
                                    <div >
                                      <b>คงเหลือ : </b>
                                      {/* TOTPRC - TOTDWN  คงเหลือ */}
                                      {currencyFormatOne(item?.TOTPRC - item?.TOTDWN)} บาท{" "}
                                    </div>
                                    <div >
                                      <b>งวดละ : </b>
                                      {/* TOT_UPAY  งวดละ */}
                                      {currencyFormatOne(item?.TOT_UPAY)} บาท
                                    </div>
                                    {dataCalRush?.map((item, index) => {
                                      {/* ลูกหนี้อื่นๆ OTHR */ }
                                      return (
                                        <React.Fragment key={`${item.id}-${index}`}>
                                          {dataCal({ item, index })}
                                        </React.Fragment>
                                      );
                                      // return dataCal({ item, index, key: `{item} - ${index}` });
                                    })}

                                    <div>
                                      {/* T_NOPAY  งวดทั้งหมด */}
                                      <b>ผ่อนจำนวน : </b> {item?.T_NOPAY} งวด
                                    </div>
                                    <div>
                                      {/* smpay  ผ่อนมาแล้ว */}
                                      <b>ผ่อนมาแล้ว : </b><span style={{ color: 'red' }}>ประมาณการ</span> ({(item?.SMPAY / item?.TOT_UPAY)?.toFixed(2)}) งวด
                                    </div>
                                    <div >
                                      {/* smpay  จ่ายมาแล้ว */}
                                      <b>จ่ายมาแล้ว : </b>{currencyFormatOne(item?.SMPAY)} บาท
                                    </div>
                                    <div>
                                      <u>
                                        <b>ยอดคงเหลือ : </b>
                                        {/* TOTPRC (คงเหลือ) - SMPAY (เงินที่จ่ายมา) = ยอดคงเหลือ */}
                                        {currencyFormatOne(item?.TOTPRC - item?.SMPAY)} บาท
                                      </u>
                                    </div>

                                    <div >
                                      <b>ชำระงวดล่าสุด : </b>{" "}
                                      {/* LPAYD  ชำระงวดล่าสุดชำระงวดล่าสุด */}
                                      {dayjs(item?.LPAYD).format("DD/MM/YYYY")}
                                    </div>
                                    <div >
                                      {/* LPAYA  ยอดชำระล่าสุด */}
                                      <b>ยอดชำระล่าสุด : </b> {currencyFormatOne(item?.LPAYA)} บาท
                                    </div>
                                    <div >
                                      {/* EXP_AMT  ค้างค่างวด */}
                                      <b>ค้างค่างวด : </b> {currencyFormatOne(item?.EXP_AMT)} บาท
                                    </div>
                                    {item?.DATA_TYPE === "vsfhp" || //2 
                                      item?.DATA_TYPE === "sfhp" || //8
                                      item?.DATA_TYPE === "lsfhp"  //1
                                      ?
                                      <>
                                        {dataCalRush?.map((item, index) => {
                                          {/*
                                           FOLLOW  ถ้าเป็นบัญชี 1,2,3 จะเป็น "เบี้ยปรับ" 
                                          ถ้าเป็นบัญชี 3 จะเป็น "ค่าทวงถาม" 
                                          */ }
                                          return (
                                            <React.Fragment key={`${item.id}-${index}`}>
                                              {dataFollow({ item, index })}
                                            </React.Fragment>
                                          );
                                          // return dataFollow({ item, index, key: `{item} - ${index}` });
                                        })}
                                      </>
                                      :
                                      <div>
                                        {/*  บัญชี 3 เบี้ยปรับเป็น 0 */}
                                        <b>เบี้ยปรับ : </b> {currencyFormatOne(0)}{" "}
                                        บาท
                                      </div>
                                    }
                                    <div>
                                      {/* KANG  เงินกั๊ก */}
                                      <b>เงินกั๊ก : </b> {currencyFormatOne(item?.KANG)} บาท
                                    </div>
                                    <div>
                                      {/* KANGOTH  ค้างชำระอื่นๆ หรือ เงินตั้งพักค่างวด */}
                                      <b>เงินตั้งพักค่างวด : </b> {currencyFormatOne(item?.KANGOTH)}{" "}
                                      บาท
                                    </div>

                                    {item?.DATA_TYPE === "rpsl" ?
                                      <>
                                        {/* FOLLOW  ค่าทวงถาม เฉพาะบัญชี 3  */}
                                        {dataCalRush?.map((item, index) => {
                                          return (
                                            <React.Fragment key={`${item.id}-${index}`}>
                                              {dataFollow({ item, index })}
                                            </React.Fragment>
                                          );
                                          // return dataFollow({ item, index, key: `{item} - ${index}` });
                                        })}
                                      </>
                                      :
                                      null
                                    }

                                    <div
                                      style={{ color: "red", fontSize: '18px' }}
                                    >
                                      {/* PAYLIMITAMT  ชำระขั้นต่ำ */}
                                      <b>ชำระขั้นต่ำ :  {currencyFormatOne(item?.PAYLIMITAMT)}{" "} </b>
                                      บาท
                                    </div>
                                  </aside>
                                </Col>
                              </Row>
                            </Row>
                            <Row>
                              <Col span={23} style={{ textAlign: "left", marginTop: "10px" }}>
                                <div
                                  style={{
                                    color: "red",
                                    textAlign: "center",
                                    margin: "auto",
                                    fontSize: '18px'
                                  }}
                                >
                                  {" "}
                                  <b>ค้างทั้งหมด :
                                    {/* 
                                  EXP_PRD  งวดที่ค้างทั้งหมด
                                  EXP_FRM  งวดที่ค้างเริ่มต้น
                                  EXP_TO  งวดที่ค้างปัจจุบัน
                                  */}
                                    {!item?.EXP_PRD ? "-" : item?.EXP_PRD} งวด จากงวดที่:{" "}
                                    {!item?.EXP_FRM ? "-" : item?.EXP_FRM} ถึงงวดที่:{" "}
                                    {!item?.EXP_TO ? "-" : item?.EXP_TO}
                                  </b>
                                </div>
                              </Col>
                            </Row>
                            <Divider style={{ margin: 3 }} />
                            <Row justify={'center'} style={{ width: '100%' }}>
                              <Col span={24} style={{ textAlign: 'center', alignItems: 'center' }}>
                                <Card style={{ alignItems: 'center' }}>
                                  <div
                                    className="center"
                                    style={{
                                      fontWeight: "bold",
                                      marginTop: "10px",
                                      textWrap: "brek-word",
                                      margin: "5px",
                                    }}
                                  >
                                    {/* MEMO1  note หรือ หมายเหตุ */}
                                    <span style={{ marginTop: "20px" }}>หมายเหตุ : </span>
                                    {!item?.MEMO1 ? "-" : item?.MEMO1}
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                            <Divider />
                            {data.approvalStatus === 3 && !data.flag ?
                              <>

                                <Row justify={"center"} style={{ width: "100%" }} align={'middle'}>
                                  <Col span={24} style={{
                                    display: "flex",
                                    justifyContent: "center", // จัดตรงกลางแนวนอน
                                    alignItems: "center", // จัดตรงกลางแนวตั้ง
                                    textAlign: "center",
                                  }}>
                                    <div style={{
                                      display: "flex",
                                    }}>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                          marginRight: '20px'
                                        }}
                                      >
                                        <b>
                                          <u>การวิเคราะห์ : </u>
                                        </b>
                                      </span>
                                      <Form.Item
                                        name="conditionsed"
                                        label=""
                                        rules={[{ required: true, message: "กรุณาเลือกตัวเลือก" }]}
                                      >
                                        <Radio.Group
                                          style={{ display: 'flex', flexDirection: 'column' }}
                                          onChange={handleRadioChange}
                                        >
                                          <Radio value={1} style={{ fontSize: "18px", color: 'green' }}>
                                            {
                                              dataAcc === 1 ?
                                                <div style={{ backgroundColor: 'blue', color: 'white', padding: 3, borderRadius: '8px' }}>
                                                  <b>*** สัญญานี้ควรส่งชุดนอก</b>
                                                </div>
                                                : dataAcc !== 1 &&
                                                <div>
                                                  <b>*** สัญญานี้ควรส่งชุดนอก</b>
                                                </div>
                                            }
                                          </Radio>
                                          <Radio value={2} style={{ fontSize: "18px", color: 'green' }}>

                                            {
                                              dataAcc === 2 ?
                                                <div style={{ backgroundColor: 'blue', color: 'white', padding: 3, borderRadius: '8px' }}>
                                                  <b>*** สัญญานี้ควรส่งการตลาด</b>
                                                </div>
                                                : dataAcc !== 2 &&
                                                < div >
                                                  <b>*** สัญญานี้ควรส่งการตลาด</b>
                                                </div>

                                            }
                                          </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                </Row>
                                <Row justify={'center'} style={{ margin: 5 }}>
                                  <Space>
                                    <Button
                                      type="text"
                                      onClick={handleClose}
                                      style={{
                                        background: 'red',
                                        color: 'white',
                                        height: '40px'
                                      }} >
                                      <b>ปิด</b>
                                    </Button>
                                    {
                                      loading === false ?
                                        <>
                                          <Button type="text" htmlType="submit" style={{ height: '40px', background: '#389e0d', color: '#ffffff' }} >
                                            <b>ยืนยันการแก้ไข</b>
                                          </Button>
                                        </> :
                                        <>
                                          <Button type='primary' htmlType="submit" disabled
                                            style={{ height: '40px', color: 'success' }}
                                          >
                                            <b>ยืนยันการแก้ไข</b>
                                          </Button>
                                        </>
                                    }
                                  </Space>
                                </Row>
                              </>
                              : null
                            }
                          </div>
                        </div>
                      ))}
                    </>
                  ) :
                    <>
                      {dataInfo.map((item, index) => (
                        <div
                          key={item?.CONTNO ? item?.CONTNO : Math.random()}
                          ref={elementRef}
                          className="center"
                          style={{
                            // backgroundColor: "transparent", // กำหนดสีพื้นหลัง
                            margin: "0 auto",
                            border: "1px solid #ccc", // กำหนดเส้นขอบ
                            fontFamily: "Arial, Helvetica, sans-serif",
                            borderCollapse: "collapse",
                          }}
                        >
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <h5 style={{ opacity: 0.5 }}>{currentDateTime()}</h5>
                          </Col>
                          <Col span={24} className="main1">
                            <aside style={{ alignItems: 'center', textAlign: 'center', marginBottom: '5px', fontSize: '18px' }}>
                              <div>
                                <img width={"60%"} style={{ borderRadius: '10px' }} src={logo} alt="Logo" />
                              </div>
                              <div>
                                <div label="เกรด" style={{ marginRight: "5px", color: "red" }}>
                                  <b>เกรด : {item?.GRDCOD}</b>
                                </div>
                                <div label="วันที่ทำสัญญา" style={{ textAlign: "center" }}>
                                  <span>
                                    <b>วันที่ทำสัญญา : </b>
                                  </span>
                                  {!item?.SDATE ? "-" : moment(item?.SDATE).add(543, "year").format("DD/MM/YYYY ")}
                                </div>
                                <div label="เลขที่สัญญา" style={{ textAlign: "center" }}>
                                  <span>
                                    <b>เลขที่สัญญา : </b>
                                  </span>
                                  {!item?.CONTNO ? "-" : item?.CONTNO}
                                </div>
                              </div>
                            </aside>
                          </Col>
                          <Divider />
                          <Row className="main">
                            {item?.products === "land" ? (
                              <>
                                <Row justify={'center'} style={{ width: '100%' }}>
                                  <Col span={22} style={{ textAlign: 'left' }}>
                                    <div style={{ width: '100%' }}>
                                      <div label="ชื่อผู้ทำสัญญา">
                                        {" "}
                                        <b>ชื่อผู้ทำสัญญา : </b>
                                        {!item?.CNAME ? "-" : item?.CNAME}
                                      </div>
                                      <div label="ที่อยู่ผู้ทำสัญญา">
                                        {" "}
                                        <b>ที่อยู่ผู้ทำสัญญา : </b>
                                        {!item?.ADDRESS ? "-" : item?.ADDRESS}{" "}
                                        {!item?.TUMB ? "-" : item?.TUMB}{" "}
                                        {!item?.AUMP ? "-" : item?.AUMP}{" "}
                                        {!item?.PROVDES ? "-" : item?.PROVDES}{" "}
                                        {!item?.ZIP ? "-" : item?.ZIP}
                                      </div>
                                      <div label="เบอร์โทรผู้ทำสัญญา">
                                        {" "}
                                        <b>เบอร์โทรผู้ทำสัญญา : </b>
                                        {!item?.TELP ? "-" : item?.TELP}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Divider orientation="center" style={{ marginTop: "10px" }}>
                                  <b>รายละเอียดคนค้ำ</b>
                                </Divider>
                                <Row justify={'center'} style={{ width: '100%' }}>
                                  <Col span={22}>
                                    <aside>
                                      {dataGuarantor?.map((item, index) => {
                                        return (
                                          <React.Fragment key={`${item.id}-${index}`}>
                                            {dataGuarantors({ item, index })}
                                          </React.Fragment>
                                        );

                                      })}
                                      <div style={{ marginTop: "10px" }}>
                                        {" "}
                                        <b>ชื่อผู้ขาย : </b>
                                        {!item?.SALCOD ? "-" : item?.SALCOD}
                                      </div>

                                    </aside>
                                  </Col>
                                </Row>
                                <Row justify={'center'} style={{ width: '100%' }}>
                                  <Divider orientation="center" style={{ margin: 0 }}>
                                    <b>รายละเอียดที่ดิน</b>
                                  </Divider>
                                  <Col span={22} style={{ textAlign: 'left' }}>
                                    <aside>
                                      <div label="ตำบล">
                                        {" "}
                                        <b>ตำบล : </b>
                                        {!item?.MODEL ? "-" : item?.MODEL}
                                      </div>
                                      <div label="อำเภอ">
                                        {" "}
                                        <b>อำเภอ : </b>
                                        {!item?.BAAB ? "-" : item?.BAAB}
                                      </div>
                                      <div label="จังหวัด">
                                        {" "}
                                        <b>จังหวัด : </b>
                                        {!item?.TYPE ? "-" : item?.TYPE}
                                      </div>
                                      <div label="ประเภทที่ดิน">
                                        {" "}
                                        <b>ประเภทที่ดิน : </b>
                                        {!item?.COLOR ? "-" : item?.COLOR}
                                      </div>
                                      <div label="เลขที่ดิน">
                                        {" "}
                                        <b>เลขที่ดิน : </b>
                                        {!item?.REGNO ? "-" : item?.REGNO}
                                      </div>
                                      <div label="เลขโฉนดที่ดิน">
                                        {" "}
                                        <b>เลขโฉนดที่ดิน : </b>
                                        {!item?.STRNO ? "-" : item?.STRNO}
                                      </div>
                                    </aside>
                                  </Col>
                                </Row>

                              </>
                            ) : (
                              <>
                                <Row justify={'center'} style={{ width: '100%' }}>
                                  <Col span={22} style={{ textAlign: 'left' }}>
                                    <div style={{ width: '100%' }}>
                                      <div label="ชื่อผู้ทำสัญญา">
                                        {" "}
                                        <b>ชื่อผู้ทำสัญญา : </b>
                                        {!item?.CNAME ? "-" : item?.CNAME}
                                      </div>
                                      <div label="ที่อยู่ผู้ทำสัญญา">
                                        {" "}
                                        <b>ที่อยู่ผู้ทำสัญญา : </b>
                                        {!item?.ADDRESS ? "-" : item?.ADDRESS}{" "}
                                        {!item?.TUMB ? "-" : item?.TUMB}{" "}
                                        {!item?.AUMP ? "-" : item?.AUMP}{" "}
                                        {!item?.PROVDES ? "-" : item?.PROVDES}{" "}
                                        {!item?.ZIP ? "-" : item?.ZIP}
                                      </div>
                                      <div label="เบอร์โทรผู้ทำสัญญา">
                                        {" "}
                                        <b>เบอร์โทรผู้ทำสัญญา : </b>
                                        {!item?.TELP ? "-" : item?.TELP}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Divider orientation="center" style={{ marginTop: "10px" }}>
                                  <b>รายละเอียดคนค้ำ</b>
                                </Divider>
                                <Row justify={'center'} style={{ width: '100%' }}>
                                  <Col span={22}>
                                    <aside>
                                      {dataGuarantor?.map((item, index) => {
                                        return (
                                          <React.Fragment key={`${item.id}-${index}`}>
                                            {dataGuarantors({ item, index })}
                                          </React.Fragment>
                                        );
                                        // return dataGuarantors({ item, index, key: `${item} - ${index}` });
                                      })}
                                      <div style={{ marginTop: "10px" }}>
                                        {" "}
                                        <b>ชื่อผู้ขาย : </b>
                                        {!item?.SALCOD ? "-" : item?.SALCOD}
                                      </div>
                                    </aside>
                                  </Col>
                                </Row>
                                <Row justify={'center'} style={{ width: '100%' }}>
                                  <Divider orientation="center" style={{ margin: 0 }}>
                                    <b>รายละเอียดรถ</b>
                                  </Divider>
                                  <Col span={22} style={{ textAlign: 'left' }}>
                                    <aside>
                                      <div label="ยี่ห้อ">
                                        {" "}
                                        <b>ยี่ห้อ : </b>
                                        {!item?.TYPE ? "-" : item?.TYPE}
                                      </div>
                                      <div label="รุ่น">
                                        {" "}
                                        <b>รุ่น : </b>
                                        {!item?.MODEL ? "-" : item?.MODEL}
                                      </div>
                                      <div label="สี">
                                        {" "}
                                        <b>สี : </b>
                                        {!item?.COLOR ? "-" : item?.COLOR}
                                      </div>
                                      <div label="ชนิดรถ">
                                        {" "}
                                        <b>ชนิดรถ : </b>
                                        {!item?.BAAB ? "-" : item?.BAAB}
                                      </div>
                                      <div label="ทะเบียน">
                                        {" "}
                                        <b>ทะเบียน : </b>
                                        {!item?.REGNO ? "-" : item?.REGNO}
                                      </div>
                                      <div label="ปีรถ">
                                        {" "}
                                        <b>ปีรถ : </b>
                                        {!item?.MANUYR ? "-" : item?.MANUYR}
                                      </div>
                                      <div label="เลขตัวถังรถ">
                                        {" "}
                                        <b>เลขตัวถังรถ : </b>
                                        {!item?.STRNO ? "-" : item?.STRNO}
                                      </div>

                                    </aside>
                                  </Col>
                                </Row>
                              </>
                            )}
                            <Divider orientation="center">
                              <b>รายละเอียดสัญญา</b>
                            </Divider>
                            <Row justify={'center'} style={{ width: '100%' }}>
                              <Col span={22} style={{ textAlign: 'left' }}>
                                <aside >
                                  <div >
                                    <b>ราคาขาย : </b>
                                    {currencyFormatOne(item?.TOTPRC)} บาท
                                  </div>
                                  <div >
                                    <b>ชำระเงินดาว : </b>
                                    {currencyFormatOne(item?.TOTDWN)} บาท
                                  </div>
                                  <div >
                                    <b>คงเหลือ : </b>
                                    {currencyFormatOne(item?.TOTPRC - item?.TOTDWN)} บาท{" "}
                                  </div>
                                  <div>
                                    <b>งวดละ : </b>
                                    {currencyFormatOne(item?.TOT_UPAY)} บาท
                                  </div>
                                  {dataCalRush?.map((item, index) => {
                                    return (
                                      <React.Fragment key={`${item.id}-${index}`}>
                                        {dataCal({ item, index })}
                                      </React.Fragment>
                                    );
                                    // return dataCal({ item, index, key: `{item} - ${index}` });
                                  })}
                                  <div >
                                    <b>ผ่อนจำนวน : </b> {item?.T_NOPAY} งวด
                                  </div>
                                  <div>
                                    {/* smpay  ผ่อนมาแล้ว */}
                                    <b>ผ่อนมาแล้ว : </b><span style={{ color: 'red' }}>ประมาณการ</span> ({(item?.SMPAY / item?.TOT_UPAY)?.toFixed(2)}) งวด
                                  </div>
                                  <div >
                                    {/* smpay  จ่ายมาแล้ว */}
                                    <b>จ่ายมาแล้ว : </b>{currencyFormatOne(item?.SMPAY)} บาท
                                  </div>
                                  <div>
                                    <u>
                                      <b>ยอดคงเหลือ : </b>
                                      {currencyFormatOne(item?.TOTPRC - item?.SMPAY)} บาท
                                    </u>
                                  </div>
                                  <div >
                                    <b>ชำระงวดล่าสุด : </b>{" "}
                                    {dayjs(item?.LPAYD).format("DD/MM/YYYY")}
                                  </div>
                                  <div >
                                    <b>ยอดชำระล่าสุด : </b> {currencyFormatOne(item?.LPAYA)} บาท
                                  </div>
                                  <div >
                                    <b>ค้างค่างวด : </b> {currencyFormatOne(item?.EXP_AMT)} บาท
                                  </div>
                                  {item?.DATA_TYPE === "vsfhp" ||
                                    item?.DATA_TYPE === "sfhp" ||
                                    item?.DATA_TYPE === "lsfhp"
                                    ?
                                    <>
                                      {dataCalRush?.map((item, index) => {
                                        return (
                                          <React.Fragment key={`${item.id}-${index}`}>
                                            {dataFollow({ item, index })}
                                          </React.Fragment>
                                        );
                                        // return dataFollow({ item, index, key: `{item} - ${index}` });
                                      })}
                                    </>
                                    :
                                    <div>
                                      <b>เบี้ยปรับ : </b> {currencyFormatOne(0)}{" "}
                                      บาท
                                    </div>
                                  }

                                  <div >
                                    <b>เงินกั๊ก : </b> {currencyFormatOne(item?.KANG)} บาท
                                  </div>
                                  <div >
                                    <b>เงินตั้งพักค่างวด : </b> {currencyFormatOne(item?.KANGOTH)}{" "}
                                    บาท
                                  </div>
                                  {item?.DATA_TYPE === "rpsl" ?
                                    <>
                                      {dataCalRush?.map((item, index) => {
                                        return (
                                          <React.Fragment key={`${item.id}-${index}`}>
                                            {dataFollow({ item, index })}
                                          </React.Fragment>
                                        );
                                        // return dataFollow({ item, index, key: `{item} - ${index}` });
                                      })}
                                    </>
                                    :
                                    null
                                  }
                                  <div
                                    label="ชำระขั้นต่ำ"
                                    style={{ color: "red", fontSize: '18px' }}
                                  >
                                    <b>ชำระขั้นต่ำ :  {currencyFormatOne(item?.PAYLIMITAMT)}{" "} </b>
                                    บาท
                                  </div>
                                </aside>
                              </Col>
                            </Row>
                          </Row>
                          <Row>
                            <Col span={23} style={{ textAlign: "left", marginTop: "10px" }}>
                              <div
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  margin: "auto",
                                  fontSize: '18px'
                                }}
                              >
                                {" "}
                                <b>ค้างทั้งหมด :
                                  {!item?.EXP_PRD ? "-" : item?.EXP_PRD} งวด จากงวดที่:{" "}
                                  {!item?.EXP_FRM ? "-" : item?.EXP_FRM} ถึงงวดที่:{" "}
                                  {!item?.EXP_TO ? "-" : item?.EXP_TO}
                                </b>
                              </div>
                            </Col>
                          </Row>
                          <Divider style={{ margin: 3 }} />
                          <Row justify={'center'} style={{ width: '100%' }}>
                            <Col span={24} style={{ textAlign: 'center', alignItems: 'center' }}>
                              <Card style={{ alignItems: 'center' }}>
                                <div
                                  className="center"
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                    textWrap: "brek-word",
                                    margin: "5px",
                                  }}
                                >
                                  <span style={{ marginTop: "20px" }}>หมายเหตุ : </span>
                                  {!item?.MEMO1 ? "-" : item?.MEMO1}
                                </div>
                              </Card>
                            </Col>
                          </Row>
                          <Divider />
                          {data.approvalStatus === 3 && !data.flag ?
                            <>

                              <Row justify={"center"} style={{ width: "100%" }} align={'middle'}>
                                <Col span={24} style={{
                                  display: "flex",
                                  justifyContent: "center", // จัดตรงกลางแนวนอน
                                  alignItems: "center", // จัดตรงกลางแนวตั้ง
                                  textAlign: "center",
                                }}>
                                  <div style={{
                                    display: "flex",
                                  }}>
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        marginRight: '20px'
                                      }}
                                    >
                                      <b>
                                        <u>การวิเคราะห์ : </u>
                                      </b>
                                    </span>
                                    <Form.Item
                                      name="conditionsed"
                                      label=""
                                      rules={[{ required: true, message: "กรุณาเลือกตัวเลือก" }]}
                                    >
                                      <Radio.Group
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                        onChange={handleRadioChange}
                                      >
                                        <Radio value={1} style={{ fontSize: "18px", color: 'green' }}>
                                          {
                                            dataAcc === 1 ?
                                              <div style={{ backgroundColor: 'blue', color: 'white', padding: 3, borderRadius: '8px' }}>
                                                <b>*** สัญญานี้ควรส่งชุดนอก</b>
                                              </div>
                                              : dataAcc !== 1 &&
                                              <div>
                                                <b>*** สัญญานี้ควรส่งชุดนอก</b>
                                              </div>
                                          }
                                        </Radio>
                                        <Radio value={2} style={{ fontSize: "18px", color: 'green' }}>

                                          {
                                            dataAcc === 2 ?
                                              <div style={{ backgroundColor: 'blue', color: 'white', padding: 3, borderRadius: '8px' }}>
                                                <b>*** สัญญานี้ควรส่งการตลาด</b>
                                              </div>
                                              : dataAcc !== 2 &&
                                              < div >
                                                <b>*** สัญญานี้ควรส่งการตลาด</b>
                                              </div>

                                          }
                                        </Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                              <Row justify={'center'} style={{ margin: 5 }}>
                                <Space>
                                  <Button
                                    type="text"
                                    onClick={handleClose}
                                    style={{
                                      background: 'red',
                                      color: 'white',
                                      height: '40px'
                                    }} >
                                    <b>ปิด</b>
                                  </Button>
                                  {
                                    loading === false ?
                                      <>
                                        <Button type="text" htmlType="submit" style={{ height: '40px', background: '#389e0d', color: '#ffffff' }} >
                                          <b>ยืนยันการแก้ไข</b>
                                        </Button>
                                      </> :
                                      <>
                                        <Button type='primary' htmlType="submit" disabled
                                          style={{ height: '40px', color: 'success' }}
                                        >
                                          <b>ยืนยันการแก้ไข</b>
                                        </Button>
                                      </>
                                  }
                                </Space>
                              </Row>
                            </>
                            : null
                          }
                        </div>
                      ))}
                    </>
                  }
                </>
              )
                :
                (
                  <>
                    {
                      loading ?
                        <>
                          <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh", // ใช้เต็มความสูงของหน้าจอ
                          }}>
                            <Spin spinning={loading} size="large" tip="Loading..." />
                          </div>
                        </>
                        :
                        <>
                          <div
                            key={dataInfo?.CONTNO ? dataInfo?.CONTNO : Math.random()}
                            style={{
                              // backgroundColor: "#ffffff",
                              margin: "0 auto",
                              display: 'flex',
                              justifyContent: 'center',
                              fontFamily: "Arial, Helvetica, sans-serif",
                              borderCollapse: "collapse",
                            }}
                          >
                            "ไม่พบข้อมูล"
                          </div>
                        </>
                    }

                  </>
                )
              }
            </>
        }
      </Form>
      {
        isModalInstallment ?
          < ModalInstallment
            open={isModalInstallment}
            close={setIsModalInstallment}
            data={data}
            DATA_TYPE={continoBill}
          />
          : null
      }

      {
        isModalQrCode ?
          <QRCodeWithContractNo
            open={isModalQrCode}
            close={setModalQrCode}
            dataQr={continoBill}
          />
          :
          null
      }
      {contextHolder}
    </Modal >
  );
};

export default Edit_Approved;
