import React, { useEffect, useState } from "react";
import { Card, Tag, Col, Button, } from "antd";
import { mainexpeditethedebt } from "../mid/all-api";
import axios from "axios";

const Cars = () => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");


  const onQuery = async (e) => {
    console.log("e", e)
    setLoading(true);
    const url = mainexpeditethedebt;
    const headers = {
      Authorization: `Bearer ${token}`,
      Menu: JSON.stringify("25"),
    };
    await axios.get(url, { headers: headers })
      .then(async (res) => {
        let i = 1;
        if (res.data) {
          console.log("res.dataaaaaaaaaaaaaa", res.data)
          const newData = res.data.map((item) => ({
            ...item,
            key: i++,
          }));
          setLoading(false);
        } else {
          // setArrayTable([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: "10%",
      render: (text, object, key) => key + 1,
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 5,
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "CONTNO",
      key: "CONTNO",
      align: "center",
      width: "40%",
      render: (text, record1) => {
        return (
          <div>
            รายละเอียด
          </div>
        );
      }
    },
    {
      title: "สถานะสัญญา",
      dataIndex: "ApprovalStatus",
      key: "ApprovalStatus",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <>
          <Tag style={{ backgroundColor: "pink" }}>
            <b>สัญญาที่ปิดแล้ว</b>
          </Tag>
        </>
      ),
    },
  ];

  return (
    <>
      <Card style={{ height: '100vh', }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="text-center">
            <h2><b>รถ</b></h2>
          </div>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button
            style={{ margin: "10px", height: '40px', width: "50px" }}
            onClick={(e) => onQuery(e)}
          >
            <b>Search</b>
          </Button>
        </Col>


      </Card>

    </>
  );
};

export default Cars;
