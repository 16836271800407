import { Col, Form, Modal, Row, Space, Button, Divider, notification } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

function NoteSendtoCEO({ open, close, data, dataSendtoCEO, status, onChangeStatused, handleCloseModal }) {
    const [form] = Form.useForm()
    const user = localStorage.getItem("username");
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const [dataNote, setDataNote] = useState({
        noteBy: user,
        status: "1",
        noteDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    })


    const handleClose = () => {
        setIsModalOpen(false);
        close(false);
    };

    const handleGoBlack = () => {
        close(false);
    }

    const handleChangeNote = (e) => {
        setDataNote({ ...dataNote, note: e })
    }
    const successSend = (placement) => {
        api.success({
            message: <b>ส่งเคส {dataSendtoCEO?.CONTNO} สำเร็จ</b>,
            placement,
        });
    }
    const errorSend = (placement) => {
        api.error({
            message: <b>ส่งเคส {dataSendtoCEO?.CONTNO} ไม่สำเร็จ</b>,
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true);
        const param = {
            BALANCE: dataSendtoCEO.BALANCE,
            CONTNO: dataSendtoCEO?.CONTNO,
            DATA_TYPE: dataSendtoCEO.DATA_TYPE,
            GRDCOD: dataSendtoCEO.GRDCOD,
            LPAYD: dataSendtoCEO.LPAYD ? dayjs(dataSendtoCEO.LPAYD).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            NCARCST: dataSendtoCEO.NCARCST,
            SMPAY: dataSendtoCEO.SMPAY,
            TOT_UPAY: dataSendtoCEO.TOT_UPAY,
            EXP_AMT: dataSendtoCEO.EXP_AMT,
            TFDATE: dataSendtoCEO.TFDATE ? dayjs(dataSendtoCEO.TFDATE).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            TOTPRC: dataSendtoCEO.TOTPRC.toString(),
            offerBy: user,
            proposalBy: dataSendtoCEO?.SALCOD, // แก้กลับเป็น data.SALCOD
            reviewedBy: dataSendtoCEO?.CHECKER, // แก้กลับเป็น data.CHECKER
            T_NOPAY: dataSendtoCEO.T_NOPAY,
            notesOutSidetoCEO: dataNote,
        }

        console.log("param", param)
        await axios
            .post(`http://localhost:8070/api-dets/outsidetoceo`, param)
            .then(async (resReq) => {
                if (resReq.data) {
                    onChangeStatused({ ...dataSendtoCEO, approvalStatus: 28 }, status);
                    if (resReq.status === 200) {
                        successSend("top")
                        setTimeout(() => {
                            handleCloseModal()
                        }, 2000)
                    }
                }
            })
            .catch((err) => {
                errorSend("top")
                console.log(err)
                setLoading(false);
            });
    }

    return (
        <Modal
            title="ส่งเคส"
            open={open}
            onCancel={handleClose}
            footer={[null]}
        >
            <Form
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    width: '100%',
                }}

                form={form}
                onFinish={handleSubmit}
                autoComplete="off"
                initialValues={{ remember: true }}
            >
                <Row justify={'center'}>
                    <Col span={22}>
                        <Form.Item
                            name="note"
                            label=""
                        >
                            <div>
                                <span><b><u>หมายเหตุ</u></b></span><p />
                                <TextArea
                                    name="note"
                                    placeholder='กรอกหมายเหตุ'
                                    style={{ color: 'blue' }}
                                    rows={8}
                                    onChange={(e) => {
                                        handleChangeNote(e.target.value);
                                    }}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Space>
                        <Button
                            type="text"
                            onClick={handleGoBlack}
                            style={{
                                background: 'red',
                                color: 'white',
                                height: '40px'
                            }} >
                            <b>ปิด</b>
                        </Button>
                        {
                            loading === false ?
                                <>
                                    <Button type="text" htmlType="submit" style={{ height: '40px', background: '#389e0d', color: '#ffffff' }} >
                                        <b>บันทึก</b>
                                    </Button>
                                </> :
                                <>
                                    <Button type='primary' htmlType="submit" disabled
                                        style={{ height: '40px', color: 'success' }}
                                    >
                                        <b>บันทึก</b>
                                    </Button>
                                </>
                        }
                    </Space>
                </Row>
            </Form>
            {contextHolder}
        </Modal >
    )
}

export default NoteSendtoCEO